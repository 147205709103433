import {  useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { TIME_FORMAT } from 'constants/date';
import { UpdateCheckInRoomModel } from 'core/domain/hotels/models';
import { UpdateCheckInRoom } from 'core/domain/hotels/repository/updateCheckInRoom';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';
import { CheckInHistoryCustomDataModel } from 'components/pages/AccessesVerticals/hotel/CheckInListTab/resources/utils';
import { messageAtom } from 'components/atoms/MessageAtom';
import { Drawer } from './models';
import {
  getFollowingKeyUpdate,
  INITIAL_CARD1,
  INITIAL_CARD2,
  INITIAL_CARD3,
  INITIAL_DIGITAL_BOX,
  INITIAL_CARD_LIST_BOX,
  INITIAL_EMAILS,
  INITIAL_END_DATE,
  INITIAL_END_TIME,
  INITIAL_START_DATE,
  INITIAL_START_TIME,
} from './useReduceUpdateCheckInRoom/utils';
import { CheckInUpdateRoomDrawerProps } from 'components/organisms/CheckInUpdateRoomDrawer';
import { sendMessageError, updateCheckInRoomValuesToPayload, validateEmailFormat } from './utils';
import { useReduceUpdateCheckInRoom } from './useReduceUpdateCheckInRoom';
import { UpdateCardKey, UpdateCheckInInputFormKey } from './useReduceUpdateCheckInRoom/models';

const DELAY_FOLLOWING_CARD = 1000;

export const useUpdateCheckInRoom = (): CheckInUpdateRoomDrawerProps => {
  const { t } = useTranslation();
  const { token } = useUserSession();
  const { host } = useEnvironment();
  const [accessUid, setAccessUid] = useState<string>('');
  const [assetId, setAssetId] = useState<string>('');
  const [title, setTitle] = useState<string>(t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_TITLE'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState<boolean>(false);
  const [selectedRoom, setSelectedRoom] = useState<{ name: string, id: string }>({ name: '', id: '' });
  const {
    state,
    checkInRequiredFieldErrorText,
    dispatch,
    setResetAllPayload,
    setResetError,
    onChangeTime,
    setValueCard,
    setRequiredError,
  } = useReduceUpdateCheckInRoom();

  const {
    startDateState,
    endDateState,
    startTimeState,
    endTimeState,
    digitalBoxState,
    cardListBoxState,
    emailsState,
    card1State,
    card2State,
    card3State,
    qrModalState,
    modalForceState,
  } = state;

  const {
    dispatchToDigitalBoxKey,
    dispatchToEmailKey,
    dispatchToCardListBoxKey,
    dispatchToCard1Key,
    dispatchToCard2Key,
    dispatchToCard3Key,
    dispatchToStartTimeKey,
    dispatchToEndTimeKey,
    dispatchToStartDateKey,
    dispatchToEndDateKey,
    dispatchToQrModalKey,
    dispatchToModalForceKey,
  } = dispatch;

  const closeButtonText = t('_ACCESS_HOTEL_UPDATE_DRAWER_CLOSE');
  const keysSectionDescription = t('_ACCESS_HOTEL_UPDATE_DRAWER_KEYS_SECTION_DESCRIPTION');
  const keysSectionTitle = t('_ACCESS_HOTEL_UPDATE_DRAWER_KEYS_SECTION_TITLE');
  const requiredFields = t('required_fields');
  const saveButtonText = t('_ACCESS_HOTEL_UPDATE_DRAWER_SAVE');
  const checkInCard1Label = t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_1_LABEL');
  const checkInCard2Label = t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_2_LABEL');
  const checkInCard3Label = t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_CARD_3_LABEL');
  const checkInEmailErrorFormatText = t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_EMAIL_ERROR_FORMAT');

  const onUpdateCheckInRoom = (item: CheckInHistoryCustomDataModel) => {
    setAccessUid(item.id);
    setIsEdited(false);
    setAssetId(item.assets[0].assetId)
    setTitle(t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_TITLE_ROOM_NAME', { roomName: item.assets[0].alias }));
    setSelectedRoom({ name: item.assets[0].alias, id: item.assets[0].assetId });

    item.isDigital && dispatchToDigitalBoxKey({ ...INITIAL_DIGITAL_BOX, isContentVisible: true });
    item.isDigital && dispatchToEmailKey({ ...INITIAL_EMAILS, valueList: item.emails });
    item.isNfc && dispatchToCardListBoxKey({ ...INITIAL_CARD_LIST_BOX, isContentVisible: true });
    item.isNfc && dispatchToCard1Key({ ...INITIAL_CARD1, value: item.nfcCodes[0] });
    item.isNfc && dispatchToCard2Key({ ...INITIAL_CARD2, value: item.nfcCodes[1] || '', });
    item.isNfc && dispatchToCard3Key({ ...INITIAL_CARD3, value: item.nfcCodes[2] || '', });

    item.isDigital || setResetAllPayload(UpdateCheckInInputFormKey.DIGITAL_BOX);
    item.isDigital || setResetAllPayload(UpdateCheckInInputFormKey.EMAILS);
    item.isNfc || setResetAllPayload(UpdateCheckInInputFormKey.CARD_LIST_BOX);
    item.isNfc || setResetAllPayload(UpdateCheckInInputFormKey.CARD_1);
    item.isNfc || setResetAllPayload(UpdateCheckInInputFormKey.CARD_2);
    item.isNfc || setResetAllPayload(UpdateCheckInInputFormKey.CARD_3);

    dispatchToStartTimeKey({
      ...INITIAL_START_TIME,
      isDisabled: moment().unix() >= item.validFrom && moment().unix() <= item.validUntil,
      value: moment(moment.unix(item.validFrom), TIME_FORMAT)
    });
    dispatchToEndTimeKey({
      ...INITIAL_END_TIME,
      value: moment(moment.unix(item.validUntil), TIME_FORMAT),
      isDisabled: false,
    });
    dispatchToStartDateKey({
      ...INITIAL_START_DATE,
      isDisabled: moment().unix() >= item.validFrom && moment().unix() <= item.validUntil,
      value: moment.unix(item.validFrom)
    });
    dispatchToEndDateKey({
      ...INITIAL_END_DATE,
      isDisabled: false,
      value: moment.unix(item.validUntil)
    });

    setIsVisible(true);
    setIsLoading(false);

    setResetAllPayload(UpdateCheckInInputFormKey.MODAL_FORCE);
  }

  const onClose = () => {
    setIsVisible(false);
    setIsEdited(false);
  }

  const onChangeStartDate = (value: Moment | null) => {
    dispatchToStartDateKey({
        isError: !value,
        errorMessage: !value ? checkInRequiredFieldErrorText : '',
        value,
      });
  }

  const onChangeEndDate = (value: Moment | null) => {
    dispatchToEndDateKey({
        isError: !value,
        errorMessage: !value ? checkInRequiredFieldErrorText : '',
        value,
      });
  }

  const onChangeStartTime = (value: Moment | null) => {
    !!value && setResetError(UpdateCheckInInputFormKey.START_TIME);
    onChangeTime(UpdateCheckInInputFormKey.START_TIME, value)
  }

  const onChangeEndTime = (value: Moment | null) => {
    !!value && setResetError(UpdateCheckInInputFormKey.END_TIME);
    onChangeTime(UpdateCheckInInputFormKey.END_TIME, value)
  }

  const onShowDigitalBoxContent = (isVisible: boolean) => {
    const email = emailsState.value ? emailsState.value.trim() : '';
    
    !email && (
      dispatchToEmailKey({ isError: false, errorMessage: '' })
    );

    dispatchToDigitalBoxKey({ isContentVisible: isVisible });
  }

  const onShowCardListBoxContent = (isVisible: boolean) => {
    const card1 = card1State.value ? card1State.value.trim() : '';    
    !card1 && (
      dispatchToCard1Key({ isError: false, errorMessage: '' })
    );
    
    dispatchToCardListBoxKey({ isContentVisible: isVisible });
    
    if (!isVisible) {
      setResetAllPayload(UpdateCheckInInputFormKey.CARD_1);
      setResetAllPayload(UpdateCheckInInputFormKey.CARD_2);
      setResetAllPayload(UpdateCheckInInputFormKey.CARD_3);
    }
  }

  const onChangeEmailValue = async (email: string) => {
    const validatedEmail = validateEmailFormat(emailsState.value)
    dispatchToEmailKey({
      errorMessage: '',
      value: email,
      isValid: validatedEmail,
      isError: false,
    });
  };

  const onChangeCard1Value = (card: string) => {
    setValueCard(UpdateCheckInInputFormKey.CARD_1, card);
  };

  const onChangeCard2Value = (card: string) => {
    setValueCard(UpdateCheckInInputFormKey.CARD_2, card);
  };

  const onChangeCard3Value = (card: string) => {
    setValueCard(UpdateCheckInInputFormKey.CARD_3, card);
  };

  const modalCardTitle: any = {
    [UpdateCheckInInputFormKey.CARD_1]: checkInCard1Label,
    [UpdateCheckInInputFormKey.CARD_2]: checkInCard2Label,
    [UpdateCheckInInputFormKey.CARD_3]: checkInCard3Label,
  }

  const onShowQrModal = (key: UpdateCardKey) => {
    dispatchToQrModalKey({
       selectedCard: key,
       title: t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_MODAL_TITLE_QR', {
         cardName: modalCardTitle[key]
       }),
      isLoading: true,
      isVisible: true,
      isFollowingScan: true,
    });
  };

  const onCloseQrModal = () => {
    dispatchToQrModalKey({ isVisible: false });
  };
  
  const setFollowingCard = () => {
    setTimeout(() => {
      dispatchToQrModalKey({ isLoading: false });
    }, DELAY_FOLLOWING_CARD);
  };

  const onScanQrModal = (value: string) => {
    setValueCard(qrModalState.selectedCard, value);
    
    if (!value) {
      dispatchToQrModalKey({ isLoading: true });
    }

    if (value && qrModalState.isFollowingScan) {
      const cardKey = getFollowingKeyUpdate(qrModalState.selectedCard);
      
      cardKey && (
        dispatchToQrModalKey({
          isLoading: true,
          selectedCard: cardKey,
          title: t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_MODAL_TITLE_QR', {
            cardName: modalCardTitle[cardKey]
          }),
        })
      );
      
      !cardKey && dispatchToQrModalKey({ isVisible: false });
    }
    
    if (value && !qrModalState.isFollowingScan) {
      dispatchToQrModalKey({ isVisible: false });
    }
  };

  const setUsedCheckInRoom = (data: UpdateCheckInRoomModel) => {
    dispatchToModalForceKey({
      title: t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_FORCE_SAVE_MODAL_TITLE', { roomName: selectedRoom.name }),
      description: t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_FORCE_SAVE_MODAL_DESCRIPTION', { roomName: selectedRoom.name }),
      checkInData: data,
      isVisible: true,
    });
  };

  const setUnusedCheckInRoom = () => {
    setTimeout(() => {
      setIsVisible(false);
      setIsEdited(true);
      setIsLoading(false)
      setIsVisible(false);
      messageAtom.success(t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_SUCCESS'), 5);
    }, 1000)
  };

  const onUpdate = async ({ id, forceSaveMode }: { id: string, forceSaveMode: boolean }) => {
    const emailList = digitalBoxState.isContentVisible ? emailsState.valueList : [];
    const card1Value = cardListBoxState.isContentVisible ? card1State.value : '';
    const card2Value = cardListBoxState.isContentVisible ? card2State.value : '';
    const card3Value = cardListBoxState.isContentVisible ? card3State.value : '';

    const data = updateCheckInRoomValuesToPayload({
      [UpdateCheckInInputFormKey.START_DATE]: startDateState.value!,
      [UpdateCheckInInputFormKey.END_DATE]: endDateState.value!,
      [UpdateCheckInInputFormKey.START_TIME]: startTimeState.value!,
      [UpdateCheckInInputFormKey.END_TIME]: endTimeState.value!,
      [UpdateCheckInInputFormKey.EMAILS]: emailList,
      [UpdateCheckInInputFormKey.CARD_1]: card1Value,
      [UpdateCheckInInputFormKey.CARD_2]: card2Value,
      [UpdateCheckInInputFormKey.CARD_3]: card3Value,
      room: assetId,
      forceSaveMode,
    })
    const { hasBeenUsed } = await UpdateCheckInRoom({
      token,
      host,
      accessUid: id,
      data
    });

    !hasBeenUsed && setUnusedCheckInRoom();
    hasBeenUsed && setUsedCheckInRoom(data);
  };

  const onSaveDrawer = (id: string) => {
    const areThereStartDate = !!startDateState.value;
    const areThereEndDate = !!endDateState.value;
    const isThereStartTime = !!startTimeState.value;
    const isThereEndTime = !!endTimeState.value;
    const isThereDigitalBox = digitalBoxState.isContentVisible;
    const isThereEmailList = !!emailsState.valueList.length;
    const areThereCardList = cardListBoxState.isContentVisible;
    const isCard1 = card1State.value ? !!card1State.value.trim() : '';
    let isError = false;

    if (!areThereStartDate) {
      setRequiredError(UpdateCheckInInputFormKey.START_DATE);
      isError = true;
    }

    if (!areThereEndDate) {
      setRequiredError(UpdateCheckInInputFormKey.END_DATE);
      isError = true;
    }
    
    if (!isThereStartTime) {
      setRequiredError(UpdateCheckInInputFormKey.START_TIME)
      isError = true;
    }
    
    if (!isThereEndTime) {
      setRequiredError(UpdateCheckInInputFormKey.END_TIME);
      isError = true;
    }
    
    if (isThereDigitalBox && !isThereEmailList) {
      emailsState.isError || setRequiredError(UpdateCheckInInputFormKey.EMAILS);
      isError = true;
    }
    
    if (areThereCardList && !isCard1) {
      setRequiredError(UpdateCheckInInputFormKey.CARD_1)
      isError = true;
    }
    
    areThereCardList && isCard1 && (
      dispatchToCard1Key({
        isError: false,
        errorMessage: '',
      })
    );

    if (!isError) {
      setIsLoading(true);
      onUpdate({ id, forceSaveMode: false })
        .catch(error => {
           setIsLoading(false)
           sendMessageError(error)
        })
    }

  };

  const onRemoveEmailItemList = (emailItem: string) => {
    const emails = [...emailsState.valueList];
    
    dispatchToEmailKey({
      valueList: emails.filter(item => emailItem !== item),
      isError: false,
      errorMessage: '',
    });
  };

  const onEmailSelected = async (email: string) => {
    const validatedEmail = await validateEmailFormat(emailsState.value);
    
    if (validatedEmail) {
      const emails = [...emailsState.valueList];
      emails.push(email);
      
      dispatchToEmailKey({
        valueList: emails,
        value: '',
        isValid: false,
        isError: false,
        errorMessage: '',
      });
    }
    
    if (email && !validatedEmail) {
      dispatchToEmailKey({
        value: email,
        isValid: false,
        isError: true,
        errorMessage: checkInEmailErrorFormatText,
      });
    }
  };

  const onRefresh = () => dispatchToQrModalKey({ isLoading: true });

  const onFollowingScan = (checked: boolean) => {
    dispatchToQrModalKey({ isLoading: true, isFollowingScan: checked });
  };

  const onForceSaveModalAccept = async () => {
    dispatchToModalForceKey({ isLoading: true });
    onUpdate({ id: accessUid, forceSaveMode: true })
      .then(() => {
        setTimeout(() => {
          setIsVisible(false);
          setIsEdited(true);
          setIsLoading(false)
          messageAtom.success(t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_SUCCESS'), 5)
        }, 1000)
      })
      .catch(error => {
        setIsLoading(false)
        sendMessageError(error)
      })
      .finally(() => {
        dispatchToModalForceKey({ isLoading: false, isVisible: false });
      });
    }
  
  const onForceSaveModalCancel = () => {
    setIsLoading(false);
    dispatchToModalForceKey({ isVisible: false });
  }

  useEffect(() => {
    qrModalState.isLoading && setFollowingCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrModalState.isLoading]);

  useEffect(() => {
    if (cardListBoxState.isContentVisible || digitalBoxState.isContentVisible) {
      setIsDisabledSaveButton(false);
    }

    if (!cardListBoxState.isContentVisible && !digitalBoxState.isContentVisible) {
      setIsDisabledSaveButton(true);
    }
  }, [cardListBoxState.isContentVisible, digitalBoxState.isContentVisible])

  startDateState.onChangeRange = onChangeStartDate;
  endDateState.onChangeRange = onChangeEndDate;
  startTimeState.onChangeTime = onChangeStartTime;
  endTimeState.onChangeTime = onChangeEndTime;
  digitalBoxState.onVisibleContent = onShowDigitalBoxContent;
  emailsState.onEmailSelected = onEmailSelected;
  emailsState.onChangeEmail = onChangeEmailValue;
  emailsState.onRemoveEmailItemList = onRemoveEmailItemList;
  cardListBoxState.onVisibleContent = onShowCardListBoxContent;
  card1State.onChangeValue = onChangeCard1Value;
  card2State.onChangeValue = onChangeCard2Value;
  card3State.onChangeValue = onChangeCard3Value;
  qrModalState.onCancel = onCloseQrModal;
  qrModalState.onScanQr = onScanQrModal;
  qrModalState.onRefresh = onRefresh;
  qrModalState.onFollowingScan = onFollowingScan;
  modalForceState.onAccept = onForceSaveModalAccept;
  modalForceState.onCancel = onForceSaveModalCancel;
  
  const drawer: Drawer = {
    closeButtonText,
    keysSectionDescription,
    keysSectionTitle,
    requiredFields,
    saveButtonText,
    title,
    selectedRoom,
    onClose,
    onUpdateCheckInRoom,
    onShowQr: onShowQrModal,
    onSave: onSaveDrawer,
    isDisabledSaveButton,
    isLoading,
    isVisible,
    isEdited: isEdited,
  };

  return {
    drawer,
    accessUid,
    [UpdateCheckInInputFormKey.CARD_1]: card1State,
    [UpdateCheckInInputFormKey.CARD_2]: card2State,
    [UpdateCheckInInputFormKey.CARD_3]: card3State,
    [UpdateCheckInInputFormKey.CARD_LIST_BOX]: cardListBoxState,
    [UpdateCheckInInputFormKey.DIGITAL_BOX]: digitalBoxState,
    [UpdateCheckInInputFormKey.EMAILS]: emailsState,
    [UpdateCheckInInputFormKey.END_DATE]: endDateState,
    [UpdateCheckInInputFormKey.END_TIME]: endTimeState,
    [UpdateCheckInInputFormKey.MODAL_FORCE]: modalForceState,
    [UpdateCheckInInputFormKey.QR_MODAL]: qrModalState,
    [UpdateCheckInInputFormKey.START_DATE]: startDateState,
    [UpdateCheckInInputFormKey.START_TIME]: startTimeState,
  }
}
