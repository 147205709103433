export interface AssetIdListModel {
  id: string;
};

export enum GrantUserAccessToManyAssetsResponseKey {
  ADDED = 'added',
  EXISTING = 'existing',
  FAILED = 'failed',
}

export interface GrantUserAccessToManyAssetsResponseFailedPayloadModel {
  deployment_id: string;
  error: string;
}

export interface GrantUserAccessToManyAssetsResponseFailedModel {
  assetId: string;
  error: string;
}

export interface GrantUserAccessToManyAssetsResponsePayloadModel {
  [GrantUserAccessToManyAssetsResponseKey.ADDED]: string[];
  [GrantUserAccessToManyAssetsResponseKey.EXISTING]: string[];
  [GrantUserAccessToManyAssetsResponseKey.FAILED]: GrantUserAccessToManyAssetsResponseFailedPayloadModel[];
}

export interface GrantUserAccessToManyAssetsResponseModel {
  [GrantUserAccessToManyAssetsResponseKey.ADDED]: string[];
  [GrantUserAccessToManyAssetsResponseKey.EXISTING]: string[];
  [GrantUserAccessToManyAssetsResponseKey.FAILED]: GrantUserAccessToManyAssetsResponseFailedModel[];
}

export interface GrantUserAccessToManyAssetsResponseApiModel {
  data: GrantUserAccessToManyAssetsResponsePayloadModel;
}
