export const DATE_TIME_COMPLETE_FORMAT = 'HH:mm:ss DD-MM-YYYY';
export const DATE_TIME_FORMAT = 'HH:mm DD-MM-YYYY';
export const DATE_TIME_FORMAT_REVERSE = 'DD-MM-YYYY HH:mm';
export const DATE_TIME_FORMAT_REVERSE_SLASH = 'DD/MM/YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const TIME_COMPLETE_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_FORMAT_SLASH = 'DD/MM/YYYY';
export const DAY_WEEK_FORMAT = 'dddd';
export const DATE_FORMAT_1 = 'MM-DD-YYYY';
