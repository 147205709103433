import { FC } from 'react';
import LinkSearchSelectV2, { LinkSearchSelectV2Items } from 'components/organisms/LinkSearchSelectV2';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';
import { Icon } from 'components/atoms/Icons';
import './styles.scss';

interface Props {
  searchUserModalPlaceholder: string;
  selectedUser?: LinkSearchSelectV2Items;
  checkedAssetList: ProjectAssetModel[];
  boxText: string;
  onSearchUser: (query: string) => Promise<LinkSearchSelectV2Items[]>;
  onSelectUser: (item?: LinkSearchSelectV2Items) => void;
  isSearchInputOpen: boolean;
}

const MAX_LENGTH_ASSETS_LIST = 3;

const getAddress = (item: ProjectAssetModel) => {
  return `${item.streetType} ${item.streetName} ${item.blockNumber}, ${item.apartment}, ${item.city}, ${item.state}, ${item.country}`
}

const AddUserModalContentSearch: FC<Props> = ({
  searchUserModalPlaceholder,
  checkedAssetList,
  selectedUser,
  boxText,
  onSearchUser,
  onSelectUser,
  isSearchInputOpen,
}) => {
  return (
    <div>
      <LinkSearchSelectV2
        dropdownMatchSelectWidth={500}
        placeholder={searchUserModalPlaceholder}
        defaultValue={selectedUser}
        onSearch={onSearchUser}
        onSelect={onSelectUser}
        isSearchInputOpen={isSearchInputOpen}
      />
      <div className={'AddUserModalContent__box'}>
        <div className={'AddUserModalContent__icon'}>
          <Icon.InfoCircleFilled />
        </div>
        <div>
          <div>{boxText}</div>
          <ul className="AddUserModalContent__list">
            {checkedAssetList.slice(0, MAX_LENGTH_ASSETS_LIST).map((item, index) => (
              <li key={index}>{getAddress(item)}</li>
            ))}
            {checkedAssetList.length > MAX_LENGTH_ASSETS_LIST && <li>...</li>}
          </ul>
        </div>
      </div>
    </div>
  )
};

export default AddUserModalContentSearch;