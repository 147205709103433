import axios, { AxiosRequestConfig } from 'axios';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string, accessId: string) => {
  return `${host}/access/qr/${accessId}/validate`;
};

export const ValidateExternalInvitation = async ({
  accessId,
  token,
  host,
}: {
  accessId: string;
  token: string;
  host: string;
}): Promise<void> => {
  try {
    const url = getUrl(host, accessId);

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 30000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    await axios(options);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
