import {
  GrantUserAccessToManyAssetsResponseFailedModel,
  GrantUserAccessToManyAssetsResponseFailedPayloadModel,
  GrantUserAccessToManyAssetsResponseKey,
  GrantUserAccessToManyAssetsResponsePayloadModel,
} from '../models/grantUserAccessModel';

const payloadToFailedModel = ({
  deployment_id,
  error
}: GrantUserAccessToManyAssetsResponseFailedPayloadModel): GrantUserAccessToManyAssetsResponseFailedModel => {
  return {
    assetId: deployment_id,
    error,
  }
}

export const payloadToGrantUserAccessToManyAssets = ({ data }: { data: GrantUserAccessToManyAssetsResponsePayloadModel}) => {
  const added = data[GrantUserAccessToManyAssetsResponseKey.ADDED];
  const existing = data[GrantUserAccessToManyAssetsResponseKey.EXISTING];
  const failed = data[GrantUserAccessToManyAssetsResponseKey.FAILED].map(payloadToFailedModel);

  return {
    added,
    existing,
    failed,
  }
}