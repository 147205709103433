import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { DeviceWithoutGatewayResponseModel, DeviceWithoutGatewayResponsePayload } from '../models';
import { payloadToDeviceDetailWithoutGatewayResponse } from '../mappers';

const getUrl = ({ host, deviceId }: { host: string; deviceId: string }) => {
  return `${host}/gateway-cloud/devices/${deviceId}`;
};

export const GetDeviceWithoutGateway = async ({
  token,
  host,
  deviceId,
}: {
  token: string;
  host: string;
  deviceId: string;
}): Promise<DeviceWithoutGatewayResponseModel> => {
  try {
    const url = getUrl({ host, deviceId });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<DeviceWithoutGatewayResponsePayload>;
    return payloadToDeviceDetailWithoutGatewayResponse(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
