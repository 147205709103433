import { messageAtom } from "components/atoms/MessageAtom";
import { CheckInUpdateRoomDrawerFormValues } from "components/organisms/CheckInUpdateRoomDrawer";
import { UpdateCheckInRoomModel } from "core/domain/hotels/models";
import i18n from 'i18next';

const EMAIL_VALIDATION_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validateEmailFormat = (value: string): boolean => {
  const email = value.trim();
  return EMAIL_VALIDATION_REGEX.test(email);
};

export const updateCheckInRoomValuesToPayload = ({
  emails,
  startDate,
  endDate,
  startTime,
  endTime,
  card1,
  card2,
  card3,
  room,
  forceSaveMode,
}: CheckInUpdateRoomDrawerFormValues): UpdateCheckInRoomModel => {
  const validFrom = startDate.set({
    hour: startTime.hour(),
    minute: startTime.minute(),
  });

  const validUntil = endDate.set({
    hour: endTime.hour(),
    minute: endTime.minute(),
  });

  const nfc = [card1, card2, card3].filter((card) => !!card);

  return {
    validFrom: validFrom.unix(),
    validUntil: validUntil.unix(),
    emails: emails,
    codes: nfc,
    forceSaveMode,
    assetId: room,
  }
};

export const sendMessageError = (code: number) => {
  if (code === 406) {
    return messageAtom.error(i18n.t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_406_ERROR') as string, 5);
  }
  if (code === 422) {
    return messageAtom.error(i18n.t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_FORMAT_CARD_ERROR') as string, 5);
  }
  messageAtom.error(i18n.t('_ACCESS_HOTEL_UPDATE_CHECK_IN_DRAWER_ERROR') as string, 5);
};