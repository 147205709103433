import { FC } from 'react';
import ModalAtom from 'components/atoms/ModalAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import Spinner from 'components/atoms/Spinner';
import './styles.scss';

interface Props {
  title: string;
  titleIsLoading: string;
  description: string;
  descriptionIsLoading: string;
  cancelButtonText: string;
  acceptButtonText: string;
  onCancel: () => void;
  onAccept: () => void;
  isVisible: boolean;
  isLoading: boolean;
}

const ValidateExternalInvitationModal: FC<Props> = ({
  title,
  titleIsLoading,
  description,
  descriptionIsLoading,
  cancelButtonText,
  acceptButtonText,
  onCancel,
  onAccept,
  isVisible,
  isLoading,
}) => {
  return (
    <ModalAtom
      className={'ValidateExternalInvitationModal'}
      title={isLoading ? titleIsLoading : title}
      onCancel={onCancel}
      open={isVisible}
      destroyOnClose
      footer={[
        <DefaultButton
          disabled={isLoading}
          onClick={onCancel}
          title={cancelButtonText}
          key={cancelButtonText}
        />,
        <PrimaryButton
          loading={isLoading}
          onClick={onAccept}
          title={acceptButtonText}
          key={acceptButtonText}
        />,
      ]}
    >
      <div>
        {isLoading ? descriptionIsLoading : description}
      </div>
      <div className='ValidateExternalInvitationModal__loading'>
        {isLoading && <Spinner />}
      </div>
    </ModalAtom>
  );
};

export default ValidateExternalInvitationModal;
