import { FC } from 'react';
import ModalAtom, { ModalAtomProps } from 'components/atoms/ModalAtom';
import SpaceAtom from 'components/atoms/SpaceAtom';
import TextButton from 'components/atoms/Button/TextButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import Spinner from 'components/atoms/Spinner';
import './styles.scss';

interface AssignUserToMultipleAssetsModalProps extends ModalAtomProps {
  addText: string;
  cancelText: string;
  title: string;
  onAddUser: () => void;
  isHiddenAddButton: boolean;
  isLoadingAdd: boolean;
  isDisabledCancelButton: boolean;
  isDisabledAddButton: boolean;
  isScroll?: boolean;
}

const AssignUserToMultipleAssetsModal: FC<AssignUserToMultipleAssetsModalProps> = ({
  addText,
  cancelText,
  children,
  title,
  onAddUser,
  isHiddenAddButton,
  isLoadingAdd,
  isDisabledCancelButton,
  isDisabledAddButton,
  isScroll = false,
  ...props
}) => {
  return (
    <ModalAtom
      {...props}
      title={<SpaceAtom>{title}</SpaceAtom>}
      footer={[
        <TextButton
          key={'cancel'}
          onClick={props.onCancel}
          title={cancelText}
          disabled={isDisabledCancelButton}
        />,
        !isHiddenAddButton && (
          <PrimaryButton
            key={'add'}
            title={addText}
            onClick={onAddUser}
            disabled={isDisabledAddButton}
          />
        ),
      ]}
      destroyOnClose
    >
      <div className={`AssignUserToMultipleAssetsModal__wrapper ${isScroll && 'AssignUserToMultipleAssetsModal__wrapper--scroll'}`}>
        {!isLoadingAdd && children}
        {isLoadingAdd && (
          <div className={'AssignUserToMultipleAssetsModal__spinner'}>
            <Spinner />
          </div>
        )}
      </div>
    </ModalAtom>
  );
};

export default AssignUserToMultipleAssetsModal;
