import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SensorWithPasswordsModel,
  SensorWithPasswordsParametersModel,
  SensorWithPasswordsPasswordModel,
} from 'core/domain/sensors/models/sensorEntityModels';
import {
  AccessPasswordDrawerTranslations,
  PasswordBooleanType,
  PasswordConfirmation,
  PasswordDeletingType,
  PasswordType,
  SensorPasswordModel,
  TogglePassword,
  checkNfcPasswordFormatErrors,
  checkPasswordFormatErrors,
  checkPasswordSensorsChanges,
  editPassword,
  filterDeletedSensors,
  getChangedPasswordSensors,
  transformDrawerSensorToSensor,
  transformSensorToDrawerSensor,
  transformSensorVisibility,
} from './utils';
import { useAccessUpdate } from './useAccessPasswordUpdate';

export interface SensorPasswordVisibleModel extends SensorWithPasswordsPasswordModel {
  isVisible: boolean;
}

const NFC_PASSWORD_MINIMUM_DIGITS = '8';
const NFC_PASSWORD_MAXIMUM_DIGITS = '14';
const NFC_PASSWORD_VALID_DIGITS = '0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,a,b,c,d,e,f';

export const useAccessPasswordsDrawer = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [deviceParameters, setDeviceParameters] = useState<SensorWithPasswordsParametersModel>({} as SensorWithPasswordsParametersModel);
  const [adminPasswords, setAdminPasswords] = useState<SensorPasswordModel[]>([]);
  const [adminAvailablePasswords, setAdminAvailablePasswords] = useState<SensorPasswordModel[]>([]);
  const [nfcPasswords, setNfcPasswords] = useState<SensorPasswordModel[]>([]);
  const [nfcAvailablePasswords, setNfcAvailablePasswords] = useState<SensorPasswordModel[]>([]);
  const [userPasswords, setUserPasswords] = useState<SensorPasswordModel[]>([]);
  const [userAvailablePasswords, setUserAvailablePasswords] = useState<SensorPasswordModel[]>([]);
  const [isConfirmDeletingModalVisible, setIsConfirmDeletingModalVisible] = useState<boolean>(false);
  const [currentDeletingPassword, setCurrentDeletingPassword] = useState<PasswordDeletingType>({} as PasswordDeletingType);
  const [initialAdminPasswords, setInitialAdminPasswords] = useState<SensorPasswordModel[]>([]);
  const [initialUserPasswords, setInitialUserPasswords] = useState<SensorPasswordModel[]>([]);
  const [initialNfcPasswords, setInitialNfcPasswords] = useState<SensorPasswordModel[]>([]);
  const [isCloseDrawerModalVisible, setIsCloseDrawerModalVisible] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  const [isUpdateErrorModalVisible, setIsUpdateModalErrorVisible] = useState<boolean>(false);
  const [errorUpdateIds, setErrorUpdateIds] = useState<string[]>([]);
  const { errorSensorsIds, onSave, getDeviceId, resetUpdateStatusRequest, updateSuccess, updateProcessFinished, loadingUpdate } =
    useAccessUpdate();

  const isAdminPasswordsAddButtonVisible = !!adminAvailablePasswords.length;
  const isUserPasswordsAddButtonVisible = !!userAvailablePasswords.length;
  const isNfcPasswordsAddButtonVisible = !!nfcAvailablePasswords.length;
  const isAdminSectionVisible = !!initialAdminPasswords.length;
  const isUserSectionVisible = !!initialUserPasswords.length;
  const isNfcSectionVisible = !!initialNfcPasswords.length;

  const nfcParameters: SensorWithPasswordsParametersModel = {
    maxDigits: NFC_PASSWORD_MAXIMUM_DIGITS,
    minDigits: NFC_PASSWORD_MINIMUM_DIGITS,
    validDigits: NFC_PASSWORD_VALID_DIGITS,
  };

  const drawerTranslations: AccessPasswordDrawerTranslations = {
    typeLabel: t('_ACCESS_PASSWORDS_TYPE_LABEL'),
    adminLabel: t('_ACCESS_PASSWORDS_ADMIN_NUMERIC_LABEL'),
    userLabel: t('_ACCESS_PASSWORDS_NUMERIC_LABEL'),
    nfcLabel: t('_ACCESS_PASSWORDS_NFC_LABEL'),
    passwordLabel: t('_ACCESS_PASSWORDS_PASSWORD_LABEL'),
    addPasswordText: t('add'),
    showPasswordText: t('_SHOW'),
    hidePasswordText: t('_HIDE'),
    deletePasswordText: t('delete'),
    errorFormatMessage: t('_ACCESS_PASSWORD_PASSWORD_FORMAT_ERROR'),
    infoTitle: t('_ACCESS_PASSWORD_INFO_TITLE'),
    infoMinimumDigitsText: `${t('_ACCESS_PASSWORD_INFO_MIN_DIGITS_TEXT')}: ${deviceParameters.minDigits}`,
    infoMaximumDigitsText: `${t('_ACCESS_PASSWORD_INFO_MAX_DIGITS_TEXT')}: ${deviceParameters.maxDigits}`,
    infoValidDigitsText: `${t('_ACCESS_PASSWORD_INFO_VALID_DIGITS_TEXT')}: ${deviceParameters.validDigits}`,
    infoNfcTitle: t('_ACCESS_PASSWORD_INFO_NFC_TITLE'),
    infoNfcMinimumDigitsText: `${t('_ACCESS_PASSWORD_INFO_MIN_DIGITS_TEXT')}: ${nfcParameters.minDigits}`,
    infoNfcMaximumDigitsText: `${t('_ACCESS_PASSWORD_INFO_MAX_DIGITS_TEXT')}: ${nfcParameters.maxDigits}`,
    infoNfcValidDigitsText: `${t('_ACCESS_PASSWORD_INFO_VALID_DIGITS_TEXT')}: ${nfcParameters.validDigits}`,
    infoNfcExtraText: t('_ACCESS_PASSWORD_INFO_EXTRA_TEXT'),
    saveText: t('save'),
    closeText: t('close'),
  };

  const deleteModalTranslations = {
    deleteModalTitle: t('_ACCESS_PASSWORD_DELETE_MODAL_TITLE'),
    deleteModalText: t('_ACCESS_PASSWORD_DELETE_MODAL_TEXT'),
    deleteModalMainButtonText: t('_ACCESS_PASSWORD_DELETE_MODAL_MAIN_BUTTON_TEXT'),
    deleteModalSecondaryButtonText: t('_ACCESS_PASSWORD_DELETE_MODAL_SECONDARY_BUTTON_TEXT'),
  };

  const closeModalTranslations = {
    closeModalTitle: t('_ACCESS_PASSWORD_CLOSE_MODAL_TITLE'),
    closeModalText: t('_ACCESS_PASSWORD_CLOSE_MODAL_TEXT'),
    closeModalMainButtonText: t('_ACCESS_PASSWORD_CLOSE_MODAL_MAIN_BUTTON_TEXT'),
    closeModalSecondaryButtonText: t('_ACCESS_PASSWORD_CLOSE_MODAL_SECONDARY_BUTTON_TEXT'),
  };

  const updateErrorModalLiterals = {
    updateErrorModalTitle: t('_ACCESS_PASSWORDS_UPDATE_ERROR_MODAL_TITLE'),
    updateErrorModalText: t('_ACCESS_PASSWORDS_UPDATE_ERROR_MODAL_TEXT'),
    updateErrorModalMainButtonText: t('_ACCESS_PASSWORDS_UPDATE_ERROR_MODAL_MAIN_BUTTON_TEXT'),
    updateErrorModalSecondaryButtonText: t('_ACCESS_PASSWORDS_UPDATE_ERROR_MODAL_SECONDARY_BUTTON_TEXT'),
  };

  const checkAdminPasswords = (sensor: SensorPasswordModel) => {
    setAdminPasswords((state) => state.map((stateSensor) => (stateSensor.sensorId === sensor.sensorId ? sensor : stateSensor)));
  };

  const setAdminAvailablePasswordsWhenUpdatingError = (sensor: SensorPasswordModel) => {
    setAdminAvailablePasswords((state) => state.map((stateSensor) => (stateSensor.sensorId === sensor.sensorId ? sensor : stateSensor)));
  };

  const checkInitialAdminPasswordsWhenUpdatingError = (sensor: SensorPasswordModel) => {
    setInitialAdminPasswords((state) =>
      state.map((stateSensor) => (stateSensor.sensorId === sensor.sensorId && !!sensor.password ? sensor : stateSensor))
    );
  };

  const getAdminPasswords = (passwordSensor: SensorWithPasswordsPasswordModel, isErrorUpdating: boolean) => {
    const transformedSensor = transformSensorToDrawerSensor(passwordSensor, PasswordType.ADMIN_PASSWORD);

    if (isErrorUpdating) {
      checkInitialAdminPasswordsWhenUpdatingError(transformedSensor);
      transformedSensor.password === ''
        ? setAdminAvailablePasswordsWhenUpdatingError(transformedSensor)
        : checkAdminPasswords(transformedSensor);
    }

    if (!isErrorUpdating) {
      setInitialAdminPasswords((state) => [...state, ...[transformedSensor]]);
      transformedSensor.password === ''
        ? setAdminAvailablePasswords((state) => [...state, ...[transformedSensor]])
        : setAdminPasswords((state) => [...state, ...[transformedSensor]]);
    }
  };

  const checkUserPasswords = (sensor: SensorPasswordModel) => {
    setUserPasswords((state) => state.map((stateSensor) => (stateSensor.sensorId === sensor.sensorId ? sensor : stateSensor)));
  };

  const checkInitialUserPasswordsWhenUpdatingError = (sensor: SensorPasswordModel) => {
    setInitialUserPasswords((state) =>
      state.map((stateSensor) => (stateSensor.sensorId === sensor.sensorId && !!sensor.password ? sensor : stateSensor))
    );
  };

  const setUserAvailablePasswordsWhenUpdatingError = (sensor: SensorPasswordModel) => {
    setUserAvailablePasswords((state) => state.map((stateSensor) => (stateSensor.sensorId === sensor.sensorId ? sensor : stateSensor)));
  };

  const getUserPasswords = (passwordSensor: SensorWithPasswordsPasswordModel, isErrorUpdating: boolean) => {
    const transformedSensor = transformSensorToDrawerSensor(passwordSensor, PasswordType.USER_PASSWORD);

    if (isErrorUpdating) {
      checkInitialUserPasswordsWhenUpdatingError(transformedSensor);
      transformedSensor.password === ''
        ? setUserAvailablePasswordsWhenUpdatingError(transformedSensor)
        : checkUserPasswords(transformedSensor);
    }

    if (!isErrorUpdating) {
      setInitialUserPasswords((state) => [...state, ...[transformedSensor]]);
      transformedSensor.password === ''
        ? setUserAvailablePasswords((state) => [...state, ...[transformedSensor]])
        : setUserPasswords((state) => [...state, ...[transformedSensor]]);
    }
  };

  const transformNfcPasswordsFormat = (sensor: SensorWithPasswordsPasswordModel): SensorWithPasswordsPasswordModel => {
    const transformedPassword = sensor.password.split(':').join('');
    return { ...sensor, password: transformedPassword };
  };

  const checkNfcPasswords = (sensor: SensorPasswordModel) => {
    setNfcPasswords((state) => state.map((stateSensor) => (stateSensor.sensorId === sensor.sensorId ? sensor : stateSensor)));
  };

  const checkInitialNfcPasswordsWhenUpdatingError = (sensor: SensorPasswordModel) => {
    setInitialNfcPasswords((state) =>
      state.map((stateSensor) => (stateSensor.sensorId === sensor.sensorId && !!sensor.password ? sensor : stateSensor))
    );
  };

  const setNfcAvailablePasswordsWhenUpdatingError = (sensor: SensorPasswordModel) => {
    setNfcAvailablePasswords((state) => state.map((stateSensor) => (stateSensor.sensorId === sensor.sensorId ? sensor : stateSensor)));
  };

  const getNfcPasswords = (passwordSensor: SensorWithPasswordsPasswordModel, isErrorUpdating: boolean) => {
    const transformedNfcPasswordsFormats = transformNfcPasswordsFormat(passwordSensor);
    const transformedSensor = transformSensorToDrawerSensor(transformedNfcPasswordsFormats, PasswordType.NFC_PASSWORD);

    if (isErrorUpdating) {
      checkInitialNfcPasswordsWhenUpdatingError(transformedSensor);
      transformedSensor.password === ''
        ? setNfcAvailablePasswordsWhenUpdatingError(transformedSensor)
        : checkNfcPasswords(transformedSensor);
    }

    if (!isErrorUpdating) {
      setInitialNfcPasswords((state) => [...state, ...[transformedSensor]]);
      transformedSensor.password === ''
        ? setNfcAvailablePasswords((state) => [...state, ...[transformedSensor]])
        : setNfcPasswords((state) => [...state, ...[transformedSensor]]);
    }
  };

  const passwordTypeManager = {
    [PasswordType.ADMIN_PASSWORD]: getAdminPasswords,
    [PasswordType.USER_PASSWORD]: getUserPasswords,
    [PasswordType.NFC_PASSWORD]: getNfcPasswords,
  };

  const getPasswordSensorsByType = (device: SensorWithPasswordsModel, isErrorUpdating: boolean) => {
    device.sensorPasswords.forEach((sensor) => passwordTypeManager[sensor.sensorType as PasswordType](sensor, isErrorUpdating));
  };

  const onClickDevice = (device: SensorWithPasswordsModel, isErrorUpdating: boolean) => {
    setIsVisible(true);
    getDeviceId(device.sensorPasswords[0].deviceId);
    setDeviceParameters(device.deviceParameters);
    getPasswordSensorsByType(device, isErrorUpdating);
  };

  const checkAreThereChanges = () => {
    const areThereAdminChanges =
      !!initialAdminPasswords.length && checkPasswordSensorsChanges([...adminPasswords, ...adminAvailablePasswords], initialAdminPasswords);
    const areThereUserChanges =
      !!initialUserPasswords.length && checkPasswordSensorsChanges([...userPasswords, ...userAvailablePasswords], initialUserPasswords);
    const areThereNfcChanges =
      !!initialNfcPasswords.length && checkPasswordSensorsChanges([...nfcPasswords, ...nfcAvailablePasswords], initialNfcPasswords);
    return areThereAdminChanges || areThereUserChanges || areThereNfcChanges;
  };

  const onCloseDrawer = () => {
    const areThereChanges = checkAreThereChanges();
    areThereChanges ? setIsCloseDrawerModalVisible(true) : onCloseDrawerModal();
  };

  const onConfirmCloseDrawerModalWithoutSaving = () => {
    setIsCloseDrawerModalVisible(false);
  };

  const onCloseDrawerModal = () => {
    setIsCloseDrawerModalVisible(false);
    setIsVisible(false);
    setAdminAvailablePasswords([]);
    setAdminPasswords([]);
    setNfcPasswords([]);
    setNfcAvailablePasswords([]);
    setUserAvailablePasswords([]);
    setUserPasswords([]);
    setInitialAdminPasswords([]);
    setInitialUserPasswords([]);
    setInitialNfcPasswords([]);
    resetUpdateStatusRequest();
  };

  const setAdminPasswordSensorVisibility = (sensorId: string) => {
    const transformedSensors = transformSensorVisibility(sensorId, adminPasswords);
    setAdminPasswords(transformedSensors);
  };

  const setUserPasswordSensorVisibility = (sensorId: string) => {
    const transformedSensors = transformSensorVisibility(sensorId, userPasswords);
    setUserPasswords(transformedSensors);
  };

  const setNfcPasswordSensorVisibility = (sensorId: string) => {
    const transformedSensors = transformSensorVisibility(sensorId, nfcPasswords);
    setNfcPasswords(transformedSensors);
  };

  const toggleAdminPasswordTypeManager = {
    [PasswordBooleanType.VISIBILITY]: (sensorId: string) => setAdminPasswordSensorVisibility(sensorId),
    [PasswordBooleanType.EDITABLE]: (sensorId: string) => {},
  };

  const toggleUserPasswordTypeManager = {
    [PasswordBooleanType.VISIBILITY]: (sensorId: string) => setUserPasswordSensorVisibility(sensorId),
    [PasswordBooleanType.EDITABLE]: (sensorId: string) => {},
  };

  const toggleNfcPasswordTypeManager = {
    [PasswordBooleanType.VISIBILITY]: (sensorId: string) => setNfcPasswordSensorVisibility(sensorId),
    [PasswordBooleanType.EDITABLE]: (sensorId: string) => {},
  };

  const togglePasswordManager = {
    [PasswordType.ADMIN_PASSWORD]: (sensorId: string, type: PasswordBooleanType) => toggleAdminPasswordTypeManager[type](sensorId),
    [PasswordType.USER_PASSWORD]: (sensorId: string, type: PasswordBooleanType) => toggleUserPasswordTypeManager[type](sensorId),
    [PasswordType.NFC_PASSWORD]: (sensorId: string, type: PasswordBooleanType) => toggleNfcPasswordTypeManager[type](sensorId),
  };

  const onTogglePassword = ({ label, sensorId, type }: TogglePassword) => {
    togglePasswordManager[label](sensorId, type);
  };

  const editAdminPassword = (sensorId: string, password: string) => {
    const isFormatError = checkPasswordFormatErrors(password, deviceParameters);
    const transformedPasswords = editPassword(sensorId, adminPasswords, password, isFormatError);
    setAdminPasswords(transformedPasswords);
  };

  const editUserPassword = (sensorId: string, password: string) => {
    const isFormatError = checkPasswordFormatErrors(password, deviceParameters);
    const transformedPasswords = editPassword(sensorId, userPasswords, password, isFormatError);
    setUserPasswords(transformedPasswords);
  };

  const editNfcPassword = (sensorId: string, password: string) => {
    const isFormatError = checkNfcPasswordFormatErrors(password, nfcParameters);
    const transformedPasswords = editPassword(sensorId, nfcPasswords, password, isFormatError);
    setNfcPasswords(transformedPasswords);
  };

  const passwordManager = {
    [PasswordType.ADMIN_PASSWORD]: (sensorId: string, value: string) => editAdminPassword(sensorId, value),
    [PasswordType.USER_PASSWORD]: (sensorId: string, value: string) => editUserPassword(sensorId, value),
    [PasswordType.NFC_PASSWORD]: (sensorId: string, value: string) => editNfcPassword(sensorId, value),
  };

  const onChangePassword = ({ label, sensorId, value }: PasswordConfirmation) => {
    passwordManager[label](sensorId, value);
  };

  const onCloseDeleteModal = () => {
    setIsConfirmDeletingModalVisible(false);
  };

  const onDeletePassword = ({ label, sensorId }: PasswordDeletingType) => {
    setCurrentDeletingPassword({ label, sensorId });
    setIsConfirmDeletingModalVisible(true);
  };

  const deleteAdminPassword = (sensorId: string) => {
    const { selectedSensor, filteredSensors } = filterDeletedSensors(sensorId, adminPasswords);
    setAdminAvailablePasswords((state) => [...state, ...[{ ...selectedSensor, password: '', passwordConfirmation: '' }]]);
    setAdminPasswords(filteredSensors);
    setIsConfirmDeletingModalVisible(false);
  };

  const deleteUserPassword = (sensorId: string) => {
    const { selectedSensor, filteredSensors } = filterDeletedSensors(sensorId, userPasswords);
    setUserAvailablePasswords((state) => [...state, ...[{ ...selectedSensor, password: '', passwordConfirmation: '' }]]);
    setUserPasswords(filteredSensors);
    setIsConfirmDeletingModalVisible(false);
  };

  const deleteNfcPassword = (sensorId: string) => {
    const { selectedSensor, filteredSensors } = filterDeletedSensors(sensorId, nfcPasswords);
    setNfcAvailablePasswords((state) => [...state, ...[{ ...selectedSensor, password: '', passwordConfirmation: '' }]]);
    setNfcPasswords(filteredSensors);
    setIsConfirmDeletingModalVisible(false);
  };

  const deletePasswordManager = {
    [PasswordType.ADMIN_PASSWORD]: (sensorId: string) => deleteAdminPassword(sensorId),
    [PasswordType.USER_PASSWORD]: (sensorId: string) => deleteUserPassword(sensorId),
    [PasswordType.NFC_PASSWORD]: (sensorId: string) => deleteNfcPassword(sensorId),
  };

  const onClickConfirmDeletingPassword = async () => {
    const { label, sensorId } = currentDeletingPassword;
    deletePasswordManager[label](sensorId);
  };

  const addAdminPassword = () => {
    const transformedSensors = [...adminPasswords, ...[{ ...adminAvailablePasswords[0] }]];
    const filteredAdminAvailablePasswords = adminAvailablePasswords.slice(1);
    setAdminAvailablePasswords(filteredAdminAvailablePasswords);
    setAdminPasswords(transformedSensors);
  };

  const addUserPassword = () => {
    const transformedSensors = [...userPasswords, ...[{ ...userAvailablePasswords[0] }]];
    const filteredUserAvailablePasswords = userAvailablePasswords.slice(1);
    setUserAvailablePasswords(filteredUserAvailablePasswords);
    setUserPasswords(transformedSensors);
  };

  const addNfcPassword = () => {
    const newSensor: SensorPasswordModel = { ...nfcAvailablePasswords[0] };
    const transformedSensors = [...nfcPasswords, ...[{ ...newSensor, isEditable: true }]];
    const filteredNfcAvailablePasswords = nfcAvailablePasswords.slice(1);
    setNfcAvailablePasswords(filteredNfcAvailablePasswords);
    setNfcPasswords(transformedSensors);
  };

  const addPasswordManager = {
    [PasswordType.ADMIN_PASSWORD]: addAdminPassword,
    [PasswordType.USER_PASSWORD]: addUserPassword,
    [PasswordType.NFC_PASSWORD]: addNfcPassword,
  };

  const onClickAddPassword = (label: PasswordType) => {
    addPasswordManager[label]();
  };

  const transformNfcPasswordToBeSent = (sensors: SensorPasswordModel[]): SensorPasswordModel[] => {
    const transformedSensors = !!sensors.length
      ? sensors.map((sensor) => {
          const pairs: string[] = [];
          const passwordDigits = sensor.password.split('');
          passwordDigits.forEach((digit, index) => {
            index % 2 === 0 && pairs.push(`${digit}${passwordDigits[index + 1]}`);
          });
          return { ...sensor, password: pairs.join(':') };
        })
      : [];
    return transformedSensors;
  };

  const getChangedPasswordsSensors = () => {
    const changedAdminPasswords = !!initialAdminPasswords.length
      ? getChangedPasswordSensors([...adminPasswords, ...adminAvailablePasswords], initialAdminPasswords)
      : [];
    const changedUserPasswords = !!initialUserPasswords.length
      ? getChangedPasswordSensors([...userPasswords, ...userAvailablePasswords], initialUserPasswords)
      : [];
    const changedNfcPasswords = !!initialNfcPasswords.length
      ? getChangedPasswordSensors([...nfcPasswords, ...nfcAvailablePasswords], initialNfcPasswords)
      : [];
    const transformedChangedNfcPasswords = transformNfcPasswordToBeSent(changedNfcPasswords);
    return [...changedAdminPasswords, ...changedUserPasswords, ...transformedChangedNfcPasswords];
  };

  const onSavePasswordsChanges = async () => {
    const changedPasswordsSensors = getChangedPasswordsSensors();
    const transformedPasswordSensors = transformDrawerSensorToSensor(changedPasswordsSensors);
    onSave(transformedPasswordSensors);
  };

  const onConfirmCloseDrawer = async () => {
    setIsCloseDrawerModalVisible(false);
    await onSavePasswordsChanges();
  };

  const onClickConfirmUpdateRetry = async () => {
    onSavePasswordsChanges();
  };

  const onCloseUpdateErrorModal = () => {
    setIsUpdateModalErrorVisible(false);
  };

  useEffect(() => {
    !!updateSuccess && onCloseDrawerModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  useEffect(() => {
    if (updateSuccess === false && updateProcessFinished) {
      setIsUpdateModalErrorVisible(true);
      setErrorUpdateIds(errorSensorsIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProcessFinished, updateSuccess]);

  useEffect(() => {
    const havePasswordsValidChanged = checkAreThereChanges();
    setIsSaveButtonDisabled(!havePasswordsValidChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminPasswords, userPasswords, nfcPasswords]);

  const updateErrorModalConfig = {
    literals: updateErrorModalLiterals,
    onClickConfirmUpdateRetry,
    onCloseUpdateErrorModal,
    isUpdateErrorModalVisible,
    list: errorUpdateIds,
  };

  return {
    adminPasswords,
    adminAvailablePasswords,
    nfcPasswords,
    userPasswords,
    userAvailablePasswords,
    drawerTranslations,
    deleteModalTranslations,
    closeModalTranslations,
    updateErrorModalConfig,
    onClickDevice,
    onCloseDrawer,
    onCloseDrawerModal,
    onConfirmCloseDrawer,
    onTogglePassword,
    onChangePassword,
    onCloseDeleteModal,
    onConfirmCloseDrawerModalWithoutSaving,
    onDeletePassword,
    onClickConfirmDeletingPassword,
    onClickAddPassword,
    onSavePasswordsChanges,
    drawerPasswordVisible: isVisible,
    adminPasswordsAddButtonVisible: isAdminPasswordsAddButtonVisible,
    userPasswordsAddButtonVisible: isUserPasswordsAddButtonVisible,
    nfcPasswordsAddButtonVisible: isNfcPasswordsAddButtonVisible,
    confirmDeletingModalVisible: isConfirmDeletingModalVisible,
    closeDrawerModalVisible: isCloseDrawerModalVisible,
    adminSectionVisible: isAdminSectionVisible,
    userSectionVisible: isUserSectionVisible,
    nfcSectionVisible: isNfcSectionVisible,
    updateSuccess,
    updateProcessFinished,
    loadingUpdate,
    saveButtonDisabled: isSaveButtonDisabled,
  };
};
