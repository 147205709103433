import { Menu } from 'antd';
import moment from 'moment';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import ExternalInvitationsListStatusTagManager from 'components/molecules/ExternalInvitationsListStatusTagManager';
import { DATE_TIME_FORMAT_REVERSE_SLASH } from 'constants/date';
import { externalInvitationStatusText } from 'components/pages/AccessesVerticals/office/ExternalInvitationsListTab/resources/utils';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { ExternalInvitationModel, ExternalInvitationStatusEnum } from 'core/domain/offices/models';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import './styles.scss';

export interface ExternalInvitationsListColumnLiteralsModel {
  nameSurnameText: string;
  idNumberText: string;
  createByText: string;
  validText: string;
  statusText: string;
  revokeButtonText: string;
  validateButtonText: string;
  status: { [key: string]: string };
}


export interface ExternalInvitationsListColumn extends ExternalInvitationsListColumnLiteralsModel {
  filtersStatus: ColumnFilterItem[];
  onRevoke: (id: string) => void;
  onValidate: (id: string) => void;
}

export const ExternalInvitationsListColumns = ({
  nameSurnameText,
  idNumberText,
  // createByText,
  statusText,
  validText,
  revokeButtonText,
  validateButtonText,
  filtersStatus,
  onRevoke,
  onValidate,
}: ExternalInvitationsListColumn): AlfredTableColumn<ExternalInvitationModel>[] => {
  return [
    {
      title: nameSurnameText,
      key: 'name',
      width: '25%',
      render: (text, { nameSurname }) => <span>{nameSurname}</span>,
    },
    {
      title: idNumberText,
      key: 'idNumber',
      width: '15%',
      render: (text, { idNumber }) => <span>{idNumber}</span>,
    },
    // {
    //   title: createByText,
    //   key: 'createBy',
    //   width: '26%',
    //   render: (text, { createBy }) => <span>{createBy}</span>,
    // },
    {
      title: validText,
      key: 'valid',
      width: '30%',
      render: (text, { validFrom, validUntil }) => (
        <span>
          {`${moment.unix(validFrom).format(DATE_TIME_FORMAT_REVERSE_SLASH)} - ${moment.unix(validUntil).format(DATE_TIME_FORMAT_REVERSE_SLASH)}`}
        </span>
      ),
    },
    {
      title: statusText,
      key: 'status',
      width: '15%',
      filters: filtersStatus,
      render: (text, { status }) => (
        <span>
          <ExternalInvitationsListStatusTagManager
            status={status}
            text={externalInvitationStatusText[status]}
          />
        </span>
      ),
    },
    {
      key: 'actions',
      width: '5%',
      render: (text, { id, status }) => {
        const isDisabled = status === ExternalInvitationStatusEnum.EXPIRED || status === ExternalInvitationStatusEnum.REVOKED;
        const isDisabledValidated = status === ExternalInvitationStatusEnum.VALIDATED
          || status === ExternalInvitationStatusEnum.EXPIRED
          || status === ExternalInvitationStatusEnum.REVOKED;
        return (
          <>
            <ContextualMenu placement={'topRight'} menu={
              <Menu className={'Columns__wrapper'}>
                <Menu.Item
                  key={ExternalInvitationStatusEnum.VALIDATED}
                  onClick={() => onValidate(id)}
                  disabled={isDisabledValidated}
                  className={'Columns__action--default'}
                >
                  {validateButtonText}
                </Menu.Item>
                <Menu.Item
                  key={ExternalInvitationStatusEnum.REVOKED}
                  onClick={() => onRevoke(id)}
                  disabled={isDisabled}
                  className={`Columns__action${isDisabled ? '--default' : '--revoked'}`}
                >
                  {revokeButtonText}
                </Menu.Item>
              </Menu>
            } />
          </>
        )
      }
    },
  ];
};
