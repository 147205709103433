import { FC, useEffect, useRef } from 'react';
import DrawerAtom from 'components/atoms/DrawerAtom';
import TextAtom from 'components/atoms/TextAtom';
import { COLORS } from 'constants/colors';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import { AutoComplete, Checkbox, DatePicker, Form, InputRef, Tag } from 'antd';
import InputAtom from 'components/atoms/InputAtom';
import { Icon } from 'components/atoms/Icons';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { CheckInStateKey } from 'hooks/useCheckInRoom/models';
import { Drawer } from 'hooks/useCheckInUpdateRoom/models';
import { Moment } from 'moment';
import { UpdateCheckInInitialStatesModel, UpdateCheckInInputFormKey } from 'hooks/useCheckInUpdateRoom/useReduceUpdateCheckInRoom/models';
import './styles.scss';

const DRAWER_ATOM_WIDTH = '480px';

export interface CheckInUpdateRoomDrawerFormValues {
  [UpdateCheckInInputFormKey.EMAILS]: string[];
  [UpdateCheckInInputFormKey.START_DATE]: Moment;
  [UpdateCheckInInputFormKey.END_DATE]: Moment;
  [UpdateCheckInInputFormKey.START_TIME]: Moment;
  [UpdateCheckInInputFormKey.END_TIME]: Moment;
  [UpdateCheckInInputFormKey.CARD_1]: string;
  [UpdateCheckInInputFormKey.CARD_2]: string;
  [UpdateCheckInInputFormKey.CARD_3]: string;
  room: string;
  forceSaveMode: boolean;
}

export interface CheckInUpdateRoomDrawerProps extends UpdateCheckInInitialStatesModel {
  accessUid: string;
  drawer: Drawer;
}

const CheckInUpdateRoomDrawer: FC<CheckInUpdateRoomDrawerProps> = ({
  accessUid,
  drawer,
  digitalBox,
  emails,
  cardListBox,
  card1,
  card2,
  card3,
  endTime,
  startTime,
  startDate,
  endDate,
}) => {
  const emailRef = useRef<any>(null);
  const card1Ref = useRef<InputRef | null>(null);
  const card2Ref = useRef<InputRef | null>(null);
  const card3Ref = useRef<InputRef | null>(null);

  const refs = {
    [CheckInStateKey.CARD_1]: card1Ref,
    [CheckInStateKey.CARD_2]: card2Ref,
    [CheckInStateKey.CARD_3]: card3Ref,
  }

  useEffect(() => {
    digitalBox.isContentVisible && emailRef.current?.focus();
  }, [digitalBox.isContentVisible]);

  useEffect(() => {
    cardListBox.isContentVisible && card1Ref.current?.focus();
  }, [cardListBox.isContentVisible]);

  return (
    <DrawerAtom
      width={DRAWER_ATOM_WIDTH}
      className={'CheckInUpdateRoomDrawer__wrapper'}
      title={
        <TextAtom style={{ color: COLORS.text }}>{drawer.title}</TextAtom>
      }
      destroyOnClose={true}
      closable={!drawer.isLoading}
      maskClosable={!drawer.isLoading}
      onClose={drawer.onClose}
      placement={'right'}
      size={'large'}
      open={drawer.isVisible}
    >
      <main className={'CheckInUpdateRoomDrawer__main'}>
        <div className={'CheckInUpdateRoomDrawer__main-wrapper'}>
          <div className={'CheckInUpdateRoomDrawer__main-wrapper-input'}>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-label'}>{startDate.label} *</div>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-time'}>
              <div className={'CheckInUpdateRoomDrawer__main-wrapper-time-picker-input'}>
                <Form.Item
                  validateStatus={startDate.isError ? 'error' : undefined}
                  help={startDate.errorMessage}
                >
                  <DatePicker
                    className={'CheckInUpdateRoomDrawer__main-wrapper-input-time-picker'}
                    disabledDate={startDate.onDisabledDate}
                    format={startDate.formatDate}
                    disabled={startDate.isDisabled}
                    allowClear
                    defaultValue={startDate.value as Moment}
                    onChange={startDate.onChangeRange}
                  />
                </Form.Item>
              </div>
              <div className={'CheckInUpdateRoomDrawer__main-wrapper-time-picker-input'}>
                <Form.Item
                  validateStatus={endDate.isError ? 'error' : undefined}
                  help={endDate.errorMessage}
                >
                  <DatePicker
                    className={'CheckInUpdateRoomDrawer__main-wrapper-input-time-picker'}
                    disabledDate={endDate.onDisabledDate}
                    format={endDate.formatDate}
                    disabled={endDate.isDisabled}
                    allowClear
                    defaultValue={endDate.value as Moment}
                    onChange={endDate.onChangeRange}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-time'}>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-time-picker-input'}>
              <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-label'}>{startTime.label} *</div>
              <Form.Item
                validateStatus={startTime.isError ? 'error' : undefined}
                help={startTime.errorMessage}
              >
                <DatePicker.TimePicker
                  className={'CheckInUpdateRoomDrawer__main-wrapper-input-time-picker'}
                  disabledTime={startTime.onDisabledTime}
                  format={startTime.formatTime}
                  disabled={startTime.isDisabled}
                  onChange={startTime.onChangeTime}
                  defaultValue={startTime.value || undefined}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-time-picker-input'}>
              <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-label'}>{endTime.label} *</div>
              <Form.Item
                validateStatus={endTime.isError ? 'error' : undefined}
                help={endTime.errorMessage}
              >
                <DatePicker.TimePicker
                  className={'CheckInUpdateRoomDrawer__main-wrapper-input-time-picker'}
                  disabledTime={endTime.onDisabledTime}
                  format={endTime.formatTime}
                  disabled={endTime.isDisabled}
                  onChange={endTime.onChangeTime}
                  defaultValue={endTime.value || undefined}
                  allowClear
                />
              </Form.Item>
            </div>
          </div>
          <div>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-label'}>{drawer.keysSectionTitle}:</div>
            <div className={'CheckInUpdateRoomDrawer__main-wrapper-input-description'}>{drawer.keysSectionDescription}</div>
            <div
              className={
                `CheckInUpdateRoomDrawer__main-box ${digitalBox.isContentVisible && 'CheckInUpdateRoomDrawer__main-box--open'}`
              }
              onClick={() => {
                !digitalBox.isContentVisible && digitalBox.onVisibleContent(!digitalBox.isContentVisible)
              }}
            >
              <div className={'CheckInUpdateRoomDrawer__main-box-title-wrapper'}>
                <div className={'CheckInUpdateRoomDrawer__main-box-title'}>{digitalBox.title}</div>
                <Checkbox
                  checked={digitalBox.isContentVisible}
                  onChange={() => digitalBox.onVisibleContent(!digitalBox.isContentVisible)}
                />
              </div>
              {digitalBox.isContentVisible && (
                <div>
                  <div className={'CheckInUpdateRoomDrawer__main-box-label'}>{emails.label} *</div>
                  <Form.Item
                    validateStatus={emails.isError ? 'error' : undefined}
                    help={emails.errorMessage}
                  >
                    <AutoComplete
                      ref={emailRef}
                      onChange={(value) => emails.onChangeEmail(value)}
                      value={emails.value}
                      placeholder={emails.placeholder}
                      options={[{ value: emails.value }]}
                      open={emails.isValid}
                      onSelect={emails.onEmailSelected}
                      onBlur={() => emails.onEmailSelected(emails.value)}
                    />
                  </Form.Item>
                  <div className={'CheckInUpdateRoomDrawer__email-tag-list'}>
                    {emails.valueList.map(emailTag => (
                      <Tag
                        className={'CheckInUpdateRoomDrawer__email-tag'}
                        closable
                        onClose={(event) => {
                          event.preventDefault();
                          emails.onRemoveEmailItemList(emailTag);
                        }}
                      >
                        {emailTag}
                      </Tag>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div
              className={
                `CheckInUpdateRoomDrawer__main-box ${cardListBox.isContentVisible && 'CheckInUpdateRoomDrawer__main-box--open'}`
              }
              onClick={() => {
                !cardListBox.isContentVisible && (
                  cardListBox.onVisibleContent(!cardListBox.isContentVisible)
                )
              }}
            >
              <div className={'CheckInUpdateRoomDrawer__main-box-title-wrapper'}>
                <div className={'CheckInUpdateRoomDrawer__main-box-title'}>{cardListBox.title}</div>
                <Checkbox
                  checked={cardListBox.isContentVisible}
                  onChange={() => cardListBox.onVisibleContent(!cardListBox.isContentVisible)}
                />
              </div>
              {cardListBox.isContentVisible && (
                <div>
                  {[card1, card2, card3].map(card => {
                    const label = `${card.label} ${card.isRequired ? '*' : ''}`;
                    return (
                      <>
                        <div className={'CheckInUpdateRoomDrawer__main-box-label'}>{label}</div>
                        <Form.Item
                          validateStatus={card.isError ? 'error' : undefined}
                          help={card.errorMessage}
                        >
                          <InputAtom
                            style={{ width: '85%' }}
                            ref={refs[card.key]}
                            onChange={(event) => card.onChangeValue(event.target.value)}
                            value={card.value}
                            placeholder={card.placeholder}
                          />
                          <DefaultButton
                            icon={<Icon.QR />}
                            onClick={() => drawer.onShowQr(card.key)}
                          />
                        </Form.Item>
                      </>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='CheckInUpdateRoomDrawer__required-fields'>{`(*) ${drawer.requiredFields}`}</div>
      </main>
      <footer>
        <DefaultButton
          disabled={drawer.isLoading}
          onClick={drawer.onClose}
          title={drawer.closeButtonText} />
        <PrimaryButton
          disabled={drawer.isDisabledSaveButton}
          loading={drawer.isLoading}
          onClick={() => drawer.onSave(accessUid)}
          title={drawer.saveButtonText}
        />
      </footer>
    </DrawerAtom>
  );
}

export default CheckInUpdateRoomDrawer;
