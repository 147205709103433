import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CheckInListColumns } from 'components/template/CheckInListTemplate/CheckInColumns';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import {
  TABLE_PAGINATION_PAGE_DEFAULT,
  TABLE_PAGINATION_SIZE_DEFAULT,
  TablePaginationSearchValue
} from 'constants/table';
import { GetCheckInListByProjectId } from 'core/domain/hotels/repository/getCheckInListByProjectId';
import {
  CheckInHistoryCustomDataModel,
  checkInLIstLiterals,
  checkoutRoomModalLiterals,
  digitalKeyText,
  encryptNfcPassCode,
  keyReaderLiterals,
  literalsColumnsCheckInList,
  notReportedText,
  physicalKeyText,
  qrReaderModalLiterals,
  transformCheckInListToCustomData,
} from './utils';
import debounce from 'lodash.debounce';
import { messageAtom } from 'components/atoms/MessageAtom';
import { getHotelCheckInListWithPaginationPath } from 'components/pages/App/routes/access/config';
import { CheckInBasicDataModel, CheckInHistoryDataModel } from 'core/domain/hotels/models';
import { DEFAULT_PAGE_SIZE } from 'components/organisms/AlfredTable';
import { HotelAccessesTabType } from 'components/pages/AccessesPage/resources/utils';
import { CheckoutRoom } from 'core/domain/hotels/repository/checkoutRoom';
import { setNoShowAgainCheckOutModal } from 'core/domain/access/repository/setNoShowAgainCheckOutModal';
import { getNoShowAgainCheckOutModal } from 'core/domain/access/repository/getNoShowAgainCheckOutModal';
import { useUpdateCheckInRoom } from 'hooks/useCheckInUpdateRoom/useUpdateCheckInRoom';

const MINIMUM_LENGTH_TO_SEARCH = 4;
const TIME_WAIT_TO_SET_URL = 500;

const checkInHistoryDataModelToCheckInBasicDataModel = (
  data: CheckInHistoryDataModel[]
): CheckInBasicDataModel[] => (
  data
    .filter((item) => item.state === 'ACTIVE')
    .map(item => ({
      id: item.id,
      rooms: item.assets,
      validFrom: item.validFrom,
      validUntil: item.validUntil,
      nfcCodes: item.nfcCodes,
      url: item.url
    }))
)

export const useCheckInList = () => {
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [checkInList, setCheckInList] = useState<CheckInHistoryCustomDataModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingKeyReader, setIsLoadingKeyReader] = useState<boolean>(false);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState<boolean>(false);
  const [keyReaderData, setKeyReaderData] = useState<CheckInBasicDataModel[]>([]);
  const [isNotLengthKeyReader, setIsNotLengthKeyReaderData] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [totalPages, setTotalPages] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [isVisibleKeyReaderDrawer, setIsVisibleKeyReaderDrawer] = useState<boolean>(false);
  const [isVisibleQrReaderModal, setIsVisibleQrReaderModal] = useState<boolean>(false);
  const [isDisabledCheckoutButton, setIsDisabledCheckoutButton] = useState<boolean>(true);
  const [selectedCheckoutListId, setSelectedCheckoutListId] = useState<string[]>([]);
  const [nfcCode, setNfcCode] = useState<string>('');
  const [isVisibleCheckoutModal, setIsVisibleCheckoutModal] = useState<boolean>(false);
  const [isNoShowAgainCheckoutModal, setIsNoShowAgainCheckoutModal] = useState<boolean>(false);
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const updateCheckInStates = useUpdateCheckInRoom();

  const { drawer: { isVisible: isDrawerVisible, isEdited: isDrawerEdited } } = updateCheckInStates;

  useEffect(() => {
    if (!isDrawerVisible && isDrawerEdited) {
      const parsedPage = page ? Number(page) : Number(TABLE_PAGINATION_PAGE_DEFAULT);
      const parsedSize = size ? Number(size) : Number(TABLE_PAGINATION_SIZE_DEFAULT);
      getCheckInListWithPaginationByProjectId({ page: parsedPage, size: parsedSize });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerVisible, isDrawerEdited])

  const onCopyCheckInLink = (url: string) => {
    navigator.clipboard.writeText(url);
    messageAtom.success(`${t('copied')}`);
  };

  const getDataFromKeyReader = async (uuid: string) => {
    try {
      setIsLoadingKeyReader(true);
      const { data } = await GetCheckInListByProjectId({
        nfcCode: encryptNfcPassCode(uuid),
        host,
        token,
        projectId,
        pageNumber: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      })
      setKeyReaderData(checkInHistoryDataModelToCheckInBasicDataModel(data));
    } catch (error) {
      setMessageError({ description: t('_ACCESSES_ERROR_MESSAGE') });
    } finally {
      setIsLoadingKeyReader(false);
    }
  }

  const loadOptions = (query: string) => {
    if (query.length >= MINIMUM_LENGTH_TO_SEARCH) {
      setIsNotLengthKeyReaderData(false);
      getDataFromKeyReader(query);
    } else {
      setIsNotLengthKeyReaderData(true);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnSearch = useCallback(debounce(loadOptions, TIME_WAIT_TO_SET_URL), []);

  const onChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize!);
    history.push(getHotelCheckInListWithPaginationPath({ clientId, projectId, page: String(page), size: String(pageSize!) }));
  };

  const getCheckInListWithPaginationByProjectId = async ({ page, size }: { page: number; size: number }) => {
    try {
      const { data, meta } = await GetCheckInListByProjectId({
        host,
        token,
        projectId,
        pageNumber: page,
        pageSize: size,
      });
      setCheckInList(transformCheckInListToCustomData(data));
      setTotalPages(meta.totalPages);
    } catch (error) {
      setMessageError({ description: t('_ACCESSES_ERROR_MESSAGE') });
    } finally {
      setIsLoading(false);
    }
  };

  const onPrintKeysTypeColumnText = ({
    isDigital,
    isNfc
  }: { isDigital: boolean; isNfc: boolean }): string => {
    
    if (isDigital) {
      return isNfc ? `${digitalKeyText}, ${physicalKeyText}` : digitalKeyText;
    }
    
    return isNfc ? physicalKeyText : notReportedText;
  };

  const onOpenKeyReaderDrawer = () => {
    setKeyReaderData([]);
    setIsNotLengthKeyReaderData(true);
    setNfcCode('');
    setIsVisibleKeyReaderDrawer(true)
  };

  const onCloseKeyReaderDrawer = () => {
    setIsVisibleKeyReaderDrawer(false);
    setSelectedCheckoutListId([]);
  };

  const onShowQrReader = () => setIsVisibleQrReaderModal(true);
  const onCancelQrReader = () => setIsVisibleQrReaderModal(false);
  
  const onScanQrReader = (qrCode: string) => {
    setNfcCode(qrCode);
    setIsVisibleQrReaderModal(false);
  };
  
  const onChangeNfcCode = (nfcCode: string) => setNfcCode(nfcCode);

  const onCheckout = async (idList: string[]) => {
    const parsedPage = page ? Number(page) : Number(TABLE_PAGINATION_PAGE_DEFAULT);
    const parsedSize = size ? Number(size) : Number(TABLE_PAGINATION_SIZE_DEFAULT);
    try {
      await CheckoutRoom({
        checkInIdList: idList,
        token,
        host
      });
      setTimeout(async () => {
        onCloseKeyReaderDrawer();
        isVisibleCheckoutModal && setIsVisibleCheckoutModal(false);
        setIsLoadingCheckout(false);
        await getCheckInListWithPaginationByProjectId({ page: parsedPage, size: parsedSize });
        messageAtom.success(t('_ACCESSES_HOTEL_CHECKOUT_MESSAGE_SUCCESS'))
      }, 1000)
    } catch (error) {
      setMessageError({ description: t('_ACCESSES_HOTEL_CHECKOUT_MESSAGE_ERROR') });
      setIsLoadingCheckout(false);
    }
  }

  const onSecurityCheckout = async () => {
    setIsLoadingCheckout(true);
    try {
      setNoShowAgainCheckOutModal(isNoShowAgainCheckoutModal);
      await onCheckout(selectedCheckoutListId);
    } catch (error) {
      setMessageError({ description: t('_ACCESSES_HOTEL_CHECKOUT_MESSAGE_ERROR') });
    }
  }

  const onCheckoutFromColumn = (idList: string[]) => {
    setIsLoading(true);
    setSelectedCheckoutListId(idList);
    const isNoShowAgainCheckOutModal = getNoShowAgainCheckOutModal();
    !isNoShowAgainCheckOutModal && setIsVisibleCheckoutModal(true);
    isNoShowAgainCheckOutModal && onCheckout(idList);
  };

  const onEdit = (item: CheckInHistoryCustomDataModel) => {
    updateCheckInStates.drawer.onUpdateCheckInRoom(item);
  };

  const columnsCheckInList = CheckInListColumns({
    ...literalsColumnsCheckInList,
    onCopyCheckInLink,
    onPrintKeysTypeColumnText,
    onCheckout: onCheckoutFromColumn,
    onEdit,
  });

  const removeItemCheckoutListId = (checkoutId: string) => {
    const checkoutListId = selectedCheckoutListId.filter(item => checkoutId !== item);
    setSelectedCheckoutListId(checkoutListId);
  };
  
  const addItemCheckoutListId = (checkoutId: string) => {
    const checkoutListId = [...selectedCheckoutListId];
    checkoutListId.push(checkoutId);
    setSelectedCheckoutListId(checkoutListId);
  };

  const onSelectedCheckout = (checkoutId: string) => {
    if (selectedCheckoutListId.length < 1) {
      return setSelectedCheckoutListId([checkoutId]);
    }

    const hasSelected = selectedCheckoutListId.some(item => checkoutId === item);

    hasSelected && removeItemCheckoutListId(checkoutId);
    !hasSelected && addItemCheckoutListId(checkoutId);
  }
  
  const onCheckoutFromReaderKey = async () => {
    setIsLoading(true);
    const isNoShowAgainCheckOutModal = getNoShowAgainCheckOutModal();
    !isNoShowAgainCheckOutModal && setIsVisibleCheckoutModal(true);
    isNoShowAgainCheckOutModal && setIsLoadingCheckout(true);
    isNoShowAgainCheckOutModal && await onCheckout(selectedCheckoutListId);
  }

  useEffect(() => {
    const parsedPage = page ? Number(page) : Number(TABLE_PAGINATION_PAGE_DEFAULT);
    const parsedSize = size ? Number(size) : Number(TABLE_PAGINATION_SIZE_DEFAULT);

    setCurrentPage(parsedPage);
    setPageSize(parsedSize);
    setIsLoading(true);
    getCheckInListWithPaginationByProjectId({ page: parsedPage, size: parsedSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  useEffect(() => {
    !keyReaderData.length && setIsDisabledCheckoutButton(true);
    keyReaderData.length > 1 && setIsDisabledCheckoutButton(true);

    if (keyReaderData.length === 1) {
      setIsDisabledCheckoutButton(false);
      setSelectedCheckoutListId([keyReaderData[0].id]);
    }
  }, [keyReaderData])

  useEffect(() => {
    setSelectedCheckoutListId([]);
    debounceOnSearch(nfcCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nfcCode]);

  useEffect(() => {
    const tab = window.location.pathname.split('/')[8];
    if (tab === HotelAccessesTabType.CHECK_IN_LIST) {
      const parsedPage = page ? Number(page) : Number(TABLE_PAGINATION_PAGE_DEFAULT);
      const parsedSize = size ? Number(size) : Number(TABLE_PAGINATION_SIZE_DEFAULT);
      setIsLoading(true);
      getCheckInListWithPaginationByProjectId({ page: parsedPage, size: parsedSize });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    if (selectedCheckoutListId.length >= 1) {
      setIsDisabledCheckoutButton(false);
    }

    if (!selectedCheckoutListId.length) {
      setIsDisabledCheckoutButton(true);
    }
  }, [selectedCheckoutListId])

  const onAcceptCheckout = onSecurityCheckout;

  const onNoShowAgainCheckoutModal = () => {
    setIsNoShowAgainCheckoutModal(!isNoShowAgainCheckoutModal);
  };
  
  const onCancelCheckout = () => {
    setIsLoadingCheckout(false);
    setIsLoading(false);
    setIsVisibleCheckoutModal(false);
  };

  return {
    keyReaderLiterals,
    qrReaderModalLiterals,
    checkInLIstLiterals,
    checkInList,
    totalPages,
    currentPage,
    pageSize,
    nfcCode,
    keyReaderData,
    columnsCheckInList,
    checkoutSelectedItems: selectedCheckoutListId.length,
    checkoutRoomModalLiterals,
    updateCheckInStates,
    onOpenKeyReaderDrawer,
    onChangePage,
    onCloseKeyReaderDrawer,
    onShowQrReader,
    onScanQrReader,
    onCancelQrReader,
    onChangeNfcCode,
    onSelectedCheckout,
    onCheckoutFromReaderKey,
    onAcceptCheckout,
    onNoShowAgainCheckoutModal,
    onCancelCheckout,
    isNotLengthKeyReader,
    isLoading,
    isLoadingKeyReader,
    isVisibleKeyReaderDrawer,
    isVisibleQrReaderModal,
    isDisabledCheckoutButton,
    isLoadingCheckout,
    isVisibleCheckoutModal,
    isNoShowAgainCheckoutModal,
  };
};
