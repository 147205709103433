import axios, { AxiosRequestConfig } from 'axios';
import {  updateCheckInRoomToPayload } from '../mappers';
import { UpdateCheckInRoomModel } from '../models';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string, accessUid: string) => {
  return `${host}/hotels/assets/check-in/${accessUid}`;
};

const USED_CODE = 226;

export const UpdateCheckInRoom = async ({
  data,
  token,
  host,
  accessUid,
}: {
  data: UpdateCheckInRoomModel;
  token: string;
  host: string;
  accessUid: string;
}): Promise<{ hasBeenUsed: boolean }> => {
  try {
    const url = getUrl(host, accessUid);
    const mappedData = updateCheckInRoomToPayload(data);

    const options: AxiosRequestConfig = {
      url,
      method: 'PATCH',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: mappedData },
    };

    const { status } = await axios(options);

    return status === USED_CODE ? { hasBeenUsed: true } : { hasBeenUsed: false };
  } catch (error) {
    throw getErrorFormat(error);
  }
};
