import { FC } from 'react';
import { Button, ButtonProps } from 'antd';

const DangerPrimaryButton: FC<ButtonProps> = ({ title, ...props }) => {
  return (
    <Button type='primary' danger {...props}>
      {title}
    </Button>
  );
};

export default DangerPrimaryButton;
