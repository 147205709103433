import i18n from 'config/i18n';
import moment from 'moment';
import { ExternalInvitationsListColumnLiteralsModel } from 'components/template/ExternalInvitationsListTemplate/ExternalInvitationsListColumns';
import { DATE_TIME_FORMAT_REVERSE } from 'constants/date';
import { ExternalInvitationStatusEnum } from 'core/domain/offices/models';
import { ColumnFilterItem } from 'antd/lib/table/interface';

export const externalInvitationStatusText = {
  [ExternalInvitationStatusEnum.EXPIRED]: i18n.t('_ACCESSES_OFFICE_STATUS_EXPIRED'),
  [ExternalInvitationStatusEnum.PENDING]: i18n.t('_ACCESSES_OFFICE_STATUS_PENDING'),
  [ExternalInvitationStatusEnum.REVOKED]: i18n.t('_ACCESSES_OFFICE_STATUS_REVOKED'),
  [ExternalInvitationStatusEnum.VALIDATED]: i18n.t('_ACCESSES_OFFICE_STATUS_VALIDATED'),
}

export const literalsColumnsExternalInvitationsList: ExternalInvitationsListColumnLiteralsModel = {
  nameSurnameText: i18n.t('_ACCESSES_OFFICE_COLUMN_NAME_SURNAME_TEXT'),
  idNumberText: i18n.t('_ACCESSES_OFFICE_COLUMN_ID_NUMBER_TEXT'),
  createByText: i18n.t('_ACCESSES_OFFICE_COLUMN_CREATED_BY_TEXT'),
  validText: i18n.t('_ACCESSES_OFFICE_COLUMN_VALID_BY_TEXT'),
  statusText: i18n.t('_ACCESSES_OFFICE_COLUMN_STATUS_BY_TEXT'),
  revokeButtonText: i18n.t('_ACCESSES_OFFICE_COLUMN_ACTION_REVOKE'),
  validateButtonText: i18n.t('_ACCESSES_OFFICE_COLUMN_ACTION_VALIDATE'),
  status: externalInvitationStatusText,
};

export const getRevokeModalLiterals = (
  { guestName, validFrom, validUntil }:
  { guestName: string, validFrom: string, validUntil: string }
) => {
  return {
    title: i18n.t('_ACCESSES_OFFICE_REVOKE_MODAL_TITLE'),
    description: i18n.t('_ACCESSES_OFFICE_REVOKE_MODAL_DESCRIPTION', {
      guestName, validFrom, validUntil
    }),
    cancelButtonText: i18n.t('_ACCESSES_OFFICE_REVOKE_MODAL_CANCEL'),
    acceptButtonText: i18n.t('_ACCESSES_OFFICE_REVOKE_MODAL_CONTINUE'),
  }
}

export const getValidateModalLiterals = (
  { guestName, validFrom, validUntil }:
  { guestName: string, validFrom: string, validUntil: string }
) => {
  return {
    title: i18n.t('_ACCESSES_OFFICE_VALIDATE_MODAL_TITLE'),
    titleIsLoading: i18n.t('_ACCESSES_OFFICE_IS_VALIDATING_MODAL_TITLE'),
    description: i18n.t('_ACCESSES_OFFICE_VALIDATE_MODAL_DESCRIPTION', {
      guestName, validFrom, validUntil
    }),
    descriptionIsLoading:  i18n.t('_ACCESSES_OFFICE_IS_VALIDATING_MODAL_DESCRIPTION'),
    cancelButtonText: i18n.t('_ACCESSES_OFFICE_VALIDATE_MODAL_CANCEL'),
    acceptButtonText: i18n.t('_ACCESSES_OFFICE_VALIDATE_MODAL_CONTINUE'),
  }
}

export const getDecoratedValidDate = (validDate: number) => {
  return `${moment.unix(validDate).format(DATE_TIME_FORMAT_REVERSE)}`
}

export const externalInvitationItemDefault = {
  createBy: '',
  email: '',
  id: '',
  idNumber: '',
  nameSurname: '',
  status: ExternalInvitationStatusEnum.PENDING,
  url: '',
  validFrom: 0,
  validUntil: 0,
};

export const filtersStatus: ColumnFilterItem[] = [
  {
    text: externalInvitationStatusText[ExternalInvitationStatusEnum.EXPIRED],
    value: ExternalInvitationStatusEnum.EXPIRED,
  },
  {
    text: externalInvitationStatusText[ExternalInvitationStatusEnum.PENDING],
    value: ExternalInvitationStatusEnum.PENDING,
  },
  {
    text: externalInvitationStatusText[ExternalInvitationStatusEnum.REVOKED],
    value: ExternalInvitationStatusEnum.REVOKED,
  },
  {
    text: externalInvitationStatusText[ExternalInvitationStatusEnum.VALIDATED],
    value: ExternalInvitationStatusEnum.VALIDATED,
  },
]
