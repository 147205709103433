import api from 'services/api';
import { AssetTypeListValueObject } from '../entities/assetTypeListValueObject';
import { assetTypeListPath } from 'core/endspoints/assets';

export const GetAssetTypeList = async (): Promise<string[]> => {
  const path = assetTypeListPath();
  const response = await api.get<string[]>(path);
  const { list } = AssetTypeListValueObject(response.data);
  return list;
};
