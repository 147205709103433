export interface LogHistoryDataPayload {
  ts: number;
  action: string;
  usage_source: string;
  lock_type: string;
  user_id: string | null;
  user_email: string | null;
  username: string | null;
  user_agent: string | null;
  name: string | null;
  room: string | null;
}

export interface LogHistoryMetaPayload {
  unit: string;
  from: number;
  until: number;
  current_page: number;
  page_size: number;
  all_results: number;
  results: number;
  total_pages: number;
}

export interface LogHistoryLinksPayload {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface LogHistoryResponsePayload {
  data: LogHistoryDataPayload[];
  meta: LogHistoryMetaPayload;
  links: LogHistoryLinksPayload;
}

export interface LogHistoryDataModel {
  createdAt: number;
  action: string;
  usageSource: string;
  lockType: string;
  userId: string;
  userEmail: string;
  userName: string;
  userAgent: string;
  name: string;
  room: string;
}

export interface LogHistoryMetaModel {
  unit: string;
  from: number;
  until: number;
  currentPage: number;
  pageSize: number;
  allResults: number;
  results: number;
  totalPages: number;
}

export interface LogHistoryLinksModel {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export interface LogHistoryResponseModel {
  data: LogHistoryDataModel[];
  meta: LogHistoryMetaModel;
  links: LogHistoryLinksModel;
}

export enum LocalStorageAccessExternalInvitationKeys {
  NO_SHOW_AGAIN_EXTERNAL_INVITATION_REVOKE_MODAL = 'ACCESS_EXTERNAL_INVITATION_NO_SHOW_REVOKE',
  NO_SHOW_AGAIN_EXTERNAL_INVITATION_VALIDATE_MODAL = 'ACCESS_EXTERNAL_INVITATION_NO_SHOW_VALIDATE',
}

export interface GetAccessExternalInvitationsListByProjectIdProps {
  token: string;
  host: string;
  projectId: string;
  pageNumber: number;
  pageSize: number;
  statusList?: ExternalInvitationStatusEnum[];
}

export enum ExternalInvitationStatusEnum {
  PENDING = 'PENDING',
  VALIDATED = 'ACTIVE',
  REVOKED = 'REVOKED',
  EXPIRED = 'EXPIRED',
}

export interface ExternalInvitationsListMetaPayload {
  total_pages: number;
  page_size: number;
  results: number;
  all_results: number;
}

export interface ExternalInvitationsListMetaModel {
  totalPages: number;
  pageSize: number;
  results: number;
  allResults: number;
}

export interface ExternalInvitationsListLinksPayload {
  self: string;
  first: string;
  prev: string;
  next: string;
  last: string;
}

export type ExternalInvitationsListLinksModel = ExternalInvitationsListLinksPayload;

export interface ExternalInvitationsListPayload {
  data: ExternalInvitationDataPayload[],
  meta: ExternalInvitationsListMetaPayload,
  links: ExternalInvitationsListLinksPayload,
}

export interface ExternalInvitationsListResponseModel {
  data: ExternalInvitationModel[];
  meta: ExternalInvitationsListMetaModel;
  links: ExternalInvitationsListLinksModel;
}

export interface ExternalInvitationDataPayload {
  uid: string;
  name: string;
  email: string;
  id_number: string | null;
  created_by: string;
  valid_until: number;
  valid_from: number;
  url: string;
  status: string;
}
export interface ExternalInvitationModel {
  id: string;
  nameSurname: string;
  email: string;
  idNumber: string | null;
  validUntil: number;
  validFrom: number;
  url: string;
  status: ExternalInvitationStatusEnum;
  createBy: string;
}
