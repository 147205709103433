import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Pagination, Row, Col, Input } from 'antd';
import {
  ColumnType,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/lib/table/interface';
import './styles.scss';

export interface AlfredTableTemplateColumn<RecordType> extends ColumnType<RecordType> {
  editable?: boolean;
  onCell?: (record: RecordType) => { record: RecordType; dataIndex: any; title: any; isEditing: boolean };
}

export interface AlfredTableTemplateResponse<T> {
  data: T[];
  totalPages: number;
}

export type AlfredTableFetch<T> = (
  page: number,
  size: number,
  sort?: string,
  searchQuery?: string
) => Promise<AlfredTableTemplateResponse<T>>;

export type onChangeAlfredTableProp<T> = (params: {
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<T> | SorterResult<T>[]
}) => void;

export interface AlfredTableTemplateProps<T> {
  actionSection?: JSX.Element;
  actionSectionRight?: JSX.Element;
  columns: AlfredTableTemplateColumn<T>[];
  data: T[];
  kpis?: ReactNode;
  pageCurrent: number;
  pageSize: number;
  totalPages: number;
  rowSelection?: TableRowSelection<T>
  onPagination: (page: number, pageSize?: number) => void;
  onRowClick?: (data: T) => void;
  onSearch?: (value: string) => void;
  onChange?: onChangeAlfredTableProp<T>;
  isCharging: boolean;
  paginationShowed?: boolean;
}

export const DEFAULT_PAGE_SIZE = 50;
const PAGE_SIZE_OPTIONS = ['50', '75', '100'];
const MAX_SIZE_RESPONSIVE_SCREEN = 600;

const AlfredTableTemplate = <T extends {}>({
  actionSection,
  actionSectionRight,
  columns,
  data,
  pageCurrent,
  pageSize,
  totalPages,
  rowSelection,
  onPagination,
  onRowClick,
  onSearch,
  onChange,
  isCharging,
  paginationShowed = true,
}: AlfredTableTemplateProps<T>) => {
  const { t } = useTranslation();
  const styles = onRowClick ? { cursor: 'pointer' } : undefined;

  return (
    <div className='AlfredTableTemplate'>
      <Row className='AlfredTableTemplate__toolbar'>
        <Col flex='auto'>{actionSection}</Col>
        <Col>{onSearch && <Input.Search autoFocus allowClear placeholder={t('search_with_ellipsis')} onSearch={onSearch} />}</Col>
        <Col>{actionSectionRight}</Col>
      </Row>
      <Table<T>
        style={styles}
        loading={isCharging}
        columns={columns}
        dataSource={data}
        rowKey='id'
        pagination={false}
        scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        rowSelection={rowSelection}
        onRow={(item) => {
          return {
            onClick: () => onRowClick?.(item),
          };
        }}
        onChange={(pagination, filters, sorter) => {
          onChange?.({ pagination, filters, sorter })
        }}
      />
      {paginationShowed && (
        <Pagination
          className='AlfredTableTemplate__pagination'
          size='small'
          showSizeChanger={true}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          current={pageCurrent}
          total={pageSize * totalPages}
          pageSize={pageSize}
          onChange={onPagination}
        />
      )}
    </div>
  );
};

export default AlfredTableTemplate;
