import { sensorsListWithPasswordsPath } from 'core/endspoints/sensors';
import api from 'services/api';
import { SensorWithPasswordsPayloadModel } from '../models/sensorPayloadModels';
import { payloadToSensorsListWithPasswords } from '../mappers/payloadToSensorWithPassword';
import { payloadToSensorsListWithPasswordsList } from '../entities/sensorWithPasswordEntity';

export const GetSensorsListWithPasswordsByAssetId = async (assetId: string) => {
  const url = sensorsListWithPasswordsPath(assetId);
  api.defaults.headers.common['Accept-version'] = '1';

  try {
    const { data } = await api.get<SensorWithPasswordsPayloadModel[]>(url);
    const mappedData = payloadToSensorsListWithPasswords(data);
    const { list } = payloadToSensorsListWithPasswordsList(mappedData);
    return list;
  } catch (error: any) {
    throw new Error(error);
  }
};
