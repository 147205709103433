import { FC, useState } from 'react';
import { Form, Input, Select, Row, Col, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/atoms/Icons';
import SpaceAtom from 'components/atoms/SpaceAtom';

export enum TypeForm {
  edit = 'edit',
  create = 'create',
}

interface AssetFormCreateEditInputsProps {
  type: TypeForm;
  assetTypesList: string[];
  isBlocked?: boolean;
  onBlocked?: (isBlocked: boolean) => void;
}

const AssetFormCreateEditInputs: FC<AssetFormCreateEditInputsProps> = ({
  type = TypeForm.create,
  assetTypesList,
  isBlocked,
  onBlocked,
}) => {
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(false);

  const STREET_TYPES = [t('street'), t('alleyway'), t('passageway'), t('road'), t('round'), t('ride'), t('square'), t('avenue')];

  const handleOnSelectActive = () => {
    setActive(true);
  };

  const isVisibleBlocked = type === TypeForm.edit;
  const isThreeColumns = isVisibleBlocked ? 9 : 12;
  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={isThreeColumns}>
          <Form.Item name={'alias'}>
            <Input placeholder={t('deployment_alias')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={isThreeColumns}>
          <Form.Item name={'asset_type'} rules={[{ required: true, message: t('field_required'), type: 'string' }]}>
            <Select placeholder={`${t('asset_type')}*`} onChange={handleOnSelectActive} allowClear>
              {assetTypesList.map((assetType, key) => (
                <Select.Option key={key} value={assetType}>
                  {`${t(assetType)}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {isVisibleBlocked && <Col xs={24} sm={6}>
          <Form.Item name={'is_blocked'}>
            <Checkbox
              checked={isBlocked}
              onChange={() => onBlocked?.(!isBlocked)}
            >
              {t('_BLOCKED')}
            </Checkbox>
          </Form.Item>
        </Col>}
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Form.Item name={'street_type'} rules={[{ required: true, message: t('field_required'), type: 'string' }]}>
            <Select placeholder={`${t('street_type')}*`} allowClear>
              {STREET_TYPES.map((streetType, key) => (
                <Select.Option key={key} value={streetType}>
                  {streetType}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={11}>
          <Form.Item name={'street_name'} rules={[{ required: true, message: t('field_required') }]}>
            <Input placeholder={`${t('street_name')}*`} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={5}>
          <Form.Item name={'block_number'} rules={[{ required: true, message: t('field_required') }]}>
            <Input placeholder={`${t('block_number')}*`} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Form.Item name={'staircase'}>
            <Input placeholder={t('stairway')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item name={'flat'}>
            <Input placeholder={t('storey')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item name={'door'}>
            <Input placeholder={t('door')} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Form.Item name={'postal_code'} rules={[{ required: true, message: t('field_required') }]}>
            <Input placeholder={`${t('zip_code')}*`} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={16}>
          <Form.Item name={'city'} rules={[{ required: true, message: t('field_required') }]}>
            <Input placeholder={`${t('city')}*`} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item name={'province'} rules={[{ required: true, message: t('field_required') }]}>
            <Input placeholder={`${t('province')}*`} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name={'country'} rules={[{ required: true, message: t('field_required') }]}>
            <Input placeholder={`${t('country')}*`} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item name={'external_id'}>
            <Input placeholder={t('_ASSET_DETAIL_EDIT_MODAL_EXTERNAL_ID')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item name={'description'}>
            <Input placeholder={t('description')} maxLength={100} />
          </Form.Item>
        </Col>
      </Row>

      <p>(*) {t('required_fields')}</p>
      {active && type === TypeForm.edit && (
        <p>
          <SpaceAtom>
            <Icon.InfoCircle />
            <span>{t('asset_type_info')}</span>
          </SpaceAtom>
        </p>
      )}
    </>
  );
};

export default AssetFormCreateEditInputs;
