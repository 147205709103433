import i18n from 'config/i18n';
import {
  CheckInModalForceProps,
  CheckInQrModalProps,
  CheckInFormBoxProps,
  CheckInFormTextInputCardsProps,
  CheckInFormTextInputRangeProps,
  CheckInFormTextInputTimeProps,
  CardKeys,
  CheckInFormEmailInputValueProps
} from './models';
import { CheckInDrawerProps } from './models';
import { CheckInStateKey } from './models';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/date';
import { disabledDate, disabledDateTime } from 'utils/date';
import moment from 'moment';
import { CheckInRoomModel } from 'core/domain/hotels/models';

export const getInitialDigitalBox = (): CheckInFormBoxProps => ({
  title: i18n.t('_ACCESS_HOTEL_DRAWER_DIGITAL_BOX_TITLE'),
  onShowContent: () => null,
  isContentVisible: false,
  isError: false,
})

export const getInitialEmails = (): CheckInFormEmailInputValueProps => ({
  errorMessage: '',
  label: i18n.t('_ACCESS_HOTEL_DRAWER_EMAIL_LABEL'),
  placeholder: i18n.t('_ACCESS_HOTEL_DRAWER_EMAIL_PLACEHOLDER'),
  type: 'email',
  value: '',
  valueList: [],
  onChangeEmail: () => null,
  onRemoveEmailItemList: () => null,
  onEmailSelected: () => null,
  isValid: false,
  isDisabled: false,
  isError: false,
  isRequired: false,
})

export const getInitialCardListBox = (): CheckInFormBoxProps => ({
  title: i18n.t('_ACCESS_HOTEL_DRAWER_CARD_LIST_BOX_TITLE'),
  onShowContent: () => null,
  isContentVisible: false,
  isError: false,
})

const cardKeysGroup: CardKeys[] = [
  CheckInStateKey.CARD_1,
  CheckInStateKey.CARD_2,
  CheckInStateKey.CARD_3
];

export const getFollowingKey = (key: CardKeys): CardKeys | null => {
  const index = cardKeysGroup.indexOf(key);
  const length = cardKeysGroup.length - 1;
  return index === length ? null : cardKeysGroup[index + 1];
}

export const getInitialCard = ({ label, key, isRequired = false }: {label: string, key: CardKeys, isRequired?: boolean}): CheckInFormTextInputCardsProps => ({
  key,
  followingKey: getFollowingKey(key),
  errorMessage: '',
  label,
  placeholder: i18n.t('_ACCESS_HOTEL_DRAWER_CARD_PLACEHOLDER', { label }),
  value: '',
  ref: null,
  onChangeValue: () => null,
  isDisabled: false,
  isError: false,
  isRequired,
})

export const getInitialDrawer = (): CheckInDrawerProps => ({
  closeButtonText: i18n.t('_ACCESS_HOTEL_DRAWER_CLOSE'),
  keysSectionDescription: i18n.t('_ACCESS_HOTEL_DRAWER_KEYS_SECTION_DESCRIPTION'),
  keysSectionTitle: i18n.t('_ACCESS_HOTEL_DRAWER_KEYS_SECTION_TITLE'),
  requiredFields: i18n.t('required_fields'),
  saveButtonText: i18n.t('_ACCESS_HOTEL_DRAWER_SAVE'),
  selectedCard: CheckInStateKey.CARD_1,
  selectedRoom: { name: '', id: '' },
  title: i18n.t('_ACCESS_HOTEL_CHECK_IN_DRAWER_TITLE'),
  onClose: () => null,
  onSave: () => null,
  onCheckInRoom: () => null,
  onShowQr: () => false,
  isDisabledSaveButton: true,
  isLoading: false,
  isVisible: false,
})

export const getInitialModalForce = (): CheckInModalForceProps => ({
  acceptButtonText: i18n.t('_ACCESSES_HOTEL_CHECK_IN_FORCE_SAVE_MODAL_ACCEPT'),
  cancelButtonText: i18n.t('_ACCESSES_HOTEL_CHECK_IN_FORCE_SAVE_MODAL_CANCEL'),
  checkInData: {} as CheckInRoomModel,
  description: '',
  title: '',
  onAccept: () => null,
  onCancel: () => null,
  isLoading: false,
  isVisible: false,
})

export const getInitialQrModal = (): CheckInQrModalProps => ({
  title: i18n.t('_ACCESS_HOTEL_MODAL_TITLE_QR'),
  cancelText: i18n.t('_ACCESS_HOTEL_MODAL_CANCEL_QR'),
  checkText: i18n.t('_ACCESS_HOTEL_MODAL_CHECK_BOX_QR'),
  refreshText: i18n.t('_ACCESS_HOTEL_MODAL_REFRESH'),
  selectedCard: CheckInStateKey.CARD_1,
  onScanQr: () => null,
  onCancel: () => null,
  onRefresh: () => null,
  onFollowingScan: () => null,
  isVisible: false,
  isLoading: false,
  isFollowingScan: true,
})

export const getInitialRange = (): CheckInFormTextInputRangeProps => ({
  errorMessage: '',
  formatDate: DATE_FORMAT,
  label: i18n.t('_ACCESS_HOTEL_DRAWER_RANGE'),
  placeholder: '',
  values: [],
  onDisabledDate: disabledDate,
  onChangeRange: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
})

export const getInitialStartTime = (): CheckInFormTextInputTimeProps => ({
  errorMessage: '',
  formatTime: TIME_FORMAT,
  label: i18n.t('_ACCESS_HOTEL_DRAWER_START_TIME'),
  placeholder: '',
  value: null,
  onDisabledTime: disabledDateTime,
  onChangeTime: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
})

export const getInitialEndTime = (): CheckInFormTextInputTimeProps => ({
  errorMessage: '',
  formatTime: TIME_FORMAT,
  label: i18n.t('_ACCESS_HOTEL_DRAWER_END_TIME'),
  placeholder: '',
  value: moment('12:00', TIME_FORMAT),
  onDisabledTime: disabledDateTime,
  onChangeTime: () => null,
  isDisabled: false,
  isError: false,
  isRequired: true,
})
