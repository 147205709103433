import { ROUTE_PATH_ADMIN } from '../../configRoutes';

export const ROUTE_PATH_ACCESSES_CLIENT_AND_PROJECT_SELECTOR = ROUTE_PATH_ADMIN + '/access';
export const ROUTE_PATH_ACCESSES = ROUTE_PATH_ACCESSES_CLIENT_AND_PROJECT_SELECTOR + '/clients/:clientId/projects/:projectId';
export const ROUTE_PATH_ACCESSES_LIST = ROUTE_PATH_ACCESSES + '/access-list';

export const ROUTE_PATH_HOTEL_ACCESSES = ROUTE_PATH_ACCESSES + '/hotel';
export const ROUTE_PATH_RENT_ACCESSES = ROUTE_PATH_ACCESSES + '/rent';
export const ROUTE_PATH_OFFICE_ACCESSES = ROUTE_PATH_ACCESSES + '/office';
export const ROUTE_PATH_HOME_ACCESSES = ROUTE_PATH_ACCESSES + '/home';
export const ROUTE_PATH_BUILDING_ACCESSES = ROUTE_PATH_ACCESSES + '/building';
export const ROUTE_PATH_HOTEL_ROOM_ACCESSES = ROUTE_PATH_ACCESSES + '/hotel-room';
export const ROUTE_PATH_PARKING_SLOT_ACCESSES = ROUTE_PATH_ACCESSES + '/parking-slot';
export const ROUTE_PATH_VACATIONAL_RENTAL_ACCESSES = ROUTE_PATH_ACCESSES + '/vacational-rental';

export const ROUTE_PATH_HOTEL_ACCESSES_ROOM_LIST = ROUTE_PATH_HOTEL_ACCESSES + '/access-list';
export const ROUTE_PATH_RENT_ACCESSES_ROOM_LIST = ROUTE_PATH_RENT_ACCESSES + '/access-list';
export const ROUTE_PATH_OFFICE_ACCESSES_ROOM_LIST = ROUTE_PATH_OFFICE_ACCESSES + '/access-list';
export const ROUTE_PATH_HOME_ACCESSES_ROOM_LIST = ROUTE_PATH_HOME_ACCESSES + '/access-list';
export const ROUTE_PATH_BUILDING_ACCESSES_ROOM_LIST = ROUTE_PATH_BUILDING_ACCESSES + '/access-list';
export const ROUTE_PATH_HOTEL_ROOM_ACCESSES_ROOM_LIST = ROUTE_PATH_HOTEL_ROOM_ACCESSES + '/access-list';
export const ROUTE_PATH_PARKING_SLOT_ACCESSES_ROOM_LIST = ROUTE_PATH_PARKING_SLOT_ACCESSES + '/access-list';
export const ROUTE_PATH_VACATIONAL_RENTAL_ACCESSES_ROOM_LIST = ROUTE_PATH_VACATIONAL_RENTAL_ACCESSES + '/access-list';

export const ROUTE_PATH_HOTEL_ACCESS_DEVICES_LIST = ROUTE_PATH_HOTEL_ACCESSES + '/access-devices-list';
export const ROUTE_PATH_HOTEL_CHECK_IN_LIST = ROUTE_PATH_HOTEL_ACCESSES + '/check-in-list';

export const ROUTE_PATH_OFFICE_ACCESS_DEVICES_LIST = ROUTE_PATH_OFFICE_ACCESSES + '/access-devices-list';
export const ROUTE_PATH_OFFICE_ACCESSES_LOG_LIST = ROUTE_PATH_OFFICE_ACCESSES + '/log-list';
export const ROUTE_PATH_OFFICE_EXTERNAL_INVITATIONS_LIST = ROUTE_PATH_OFFICE_ACCESSES + '/external-invitations-list';

export const getAccessSelectorsPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}`;
};

export const getAccessesListPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/access-list`;
};

export const getHotelAccessesRoomListPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/hotel/access-list`;
};

export const getRentAccessesRoomListPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/rent/access-list`;
};

export const getBuildingAccessesRoomListPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/building/access-list`;
};

export const getHomeAccessesRoomListPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/home/access-list`;
};

export const getHotelRoomAccessesRoomListPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/hotel-room/access-list`;
};

export const getParkingSlotAccessesRoomListPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/parking-slot/access-list`;
};

export const getVacationalRentalAccessesRoomListPath = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/vacational-rental/access-list`;
};

export const getHotelAccessDevicesListWithPaginationPath = ({
  clientId,
  projectId,
  page,
  size,
}: {
  clientId: string;
  projectId: string;
  page: string;
  size: string;
}) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/hotel/access-devices-list?page=${page}&size=${size}`;
};

export const getOfficeAccessDevicesListWithPaginationPath = ({
  clientId,
  projectId,
  page,
  size,
}: {
  clientId: string;
  projectId: string;
  page: string;
  size: string;
}) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/office/access-devices-list?page=${page}&size=${size}`;
};

export const getHotelCheckInListWithPaginationPath = ({
  clientId,
  projectId,
  page,
  size,
}: {
  clientId: string;
  projectId: string;
  size: string;
  page: string;
}) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/hotel/check-in-list?page=${page}&size=${size}`;
};

export const getOfficeLogListWithPaginationPath = ({
  clientId,
  projectId,
  page,
  size,
}: {
  clientId: string;
  projectId: string;
  size: string;
  page: string;
}) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/office/log-list?page=${page}&size=${size}`;
};

export const getExternalInvitationsListWithPaginationPath = ({
  clientId,
  projectId,
  page,
  size,
}: {
  clientId: string;
  projectId: string;
  size: string;
  page: string;
}) => {
  return `${ROUTE_PATH_ADMIN}/access/clients/${clientId}/projects/${projectId}/office/external-invitations-list?page=${page}&size=${size}`;
};
