import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';
import { User } from 'models/users.model';
import { TableActionsMenuProps } from 'components/atoms/TableAtom';
import { getAsset } from 'services/api/assets';
import { addUserAccessToAsset, getUserSearch, revokeUserAccessToAsset } from 'services/api/users';
import { getUserPathById } from 'components/pages/App/routes/users/config';
import { Options } from 'components/organisms/LinkSearchSelect';

export interface AddUserToSearchingTableProps {
  id: string;
  username: string;
  email: string;
}

export interface AddUserToSearchingTableRespondeProps {
  value: string;
  label: ReactElement;
}

export const useAssetUsersList = () => {
  const { t } = useTranslation();
  const { assetId } = useParams<{ assetId: string }>();
  const history = useHistory();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState<string>('');
  const [unlinkUserModalVisible, setUnlinkUserModalVisible] = useState<boolean>(false);

  const usersTableActionsMenu: TableActionsMenuProps[] = [
    {
      key: 'unlinkUser',
      onClick: (userId: string) => onRevokeUser(userId),
      text: t('unlink'),
      disabled: false,
    },
  ];

  const unlinkModalTitle = t('_ASSET_DETAIL_USERS_REVOKE_MODAL_TITLE');
  const unlinkModalText = t('revoke_user_confirm');
  const unlinkModalMainButtonText = t('unlink');
  const unlinkModalSecondaryButtonText = t('cancel');

  const onUpdateUserList = async () => {
    setLoading(true);
    try {
      const { included } = await getAsset(assetId, 'users');
      included?.users && setUsers(included.users);
    } finally {
      setLoading(false);
    }
  };

  const onRevokeUser = async (userId: string) => {
    setCurrentUserId(userId);
    setUnlinkUserModalVisible(true);
  };

  const onConfirmRevokeUser = async () => {
    setLoading(true);
    try {
      await revokeUserAccessToAsset(currentUserId, assetId);
      await onUpdateUserList();
      message.info(t('unlinked_correctly'), 3);
    } catch (error) {
      console.warn(error);
    } finally {
      setUnlinkUserModalVisible(false);
    }
  };

  const onSelectUserFromTable = (user: User) => {
    history.push(getUserPathById(user.id));
  };

  const onCloseConfirmModal = () => {
    setUnlinkUserModalVisible(false);
  };

  const checkLinkedAsset = ({
    searchedUsers,
    showSearchedUsers,
  }: {
    searchedUsers: User[];
    showSearchedUsers: ({ id, username, email }: AddUserToSearchingTableProps) => AddUserToSearchingTableRespondeProps;
  }) => {
    const selectedUsers: Options[] = [];
    searchedUsers.forEach(({ id, username, email }) => {
      const exist = users.some((user) => user.id === id);
      !exist && selectedUsers.push(showSearchedUsers({ id, username, email }));
    });

    return selectedUsers;
  };

  const onSearchUser = async (
    query: string,
    showSearchedUsers: ({ id, username, email }: AddUserToSearchingTableProps) => AddUserToSearchingTableRespondeProps
  ) => {
    const searchedUsers = await getUserSearch(query);
    return checkLinkedAsset({ searchedUsers, showSearchedUsers });
  };

  const onSelectUserToBeLinkedToAsset = async (userId: string) => {
    await addUserAccessToAsset(userId, assetId);
  };

  const getUsersListAsset = async () => {
    setLoading(true);
    try {
      const asset = await getAsset(assetId, 'users');
      asset.included?.users && setUsers(asset.included.users);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsersListAsset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return {
    users,
    usersTableActionsMenu,
    unlinkModalTitle,
    unlinkModalText,
    unlinkModalMainButtonText,
    unlinkModalSecondaryButtonText,
    onUpdateUserList,
    onRevokeUser,
    onConfirmRevokeUser,
    onSelectUserFromTable,
    onCloseConfirmModal,
    onSearchUser,
    onSelectUserToBeLinkedToAsset,
    loading,
    unlinkUserModalVisible,
  };
};
