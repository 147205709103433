import { FC } from 'react';
import { Icon } from 'components/atoms/Icons';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';
import './styles.scss';

interface Props {
  list: ProjectAssetModel[];
  text: string;
}

const AddUserModalContentResponse: FC<Props> = ({
  list,
  text,
}) => {
  return (
    <div className={'AddUserModalContentResponse__box-wrapper'}>
      <div className={'AddUserModalContentResponse__box-icon-wrapper'}>
        <Icon.InfoCircleFilled className={'AddUserModalContentResponse__box-icon'} />
      </div>
      <div>
        <div>{text}</div>
        <ul className={'AddUserModalContentResponse__box-list'}>
          {list.map((item) => {
            return (
              <li>
                {`${item.streetType} ${item.streetName} ${item.blockNumber}, ${item.apartment}, ${item.city}, ${item.state}, ${item.country}`}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  );
};

export default AddUserModalContentResponse;
