import { FC, RefObject } from 'react';
import { InputRef, TableColumnsType } from 'antd';
import { ColumnType, FilterConfirmProps } from 'antd/lib/table/interface';
import { DeviceModel, DeviceSensorModel } from 'core/domain/devices/models';
import TableAtom from 'components/atoms/TableAtom';
import { ExpandedPeripheralDevicesColumns, ExpandedPeripheralDevicesTranslations } from './ExpandedColumnsPeripheralsDevices';
import { ColumnSearchTranslationsType, DeviceDataIndex } from './SearchPeripheralColumns';
import './styles.scss'

interface PeripheralDevicesProps {
  title: string;
  expandedColumnsTranslations: ExpandedPeripheralDevicesTranslations;
  gatewayId?: string;
  devices?: DeviceModel[];
  loading?: boolean;
  columns: ColumnType<DeviceModel>[];
  searchInput?: RefObject<InputRef>;
  columnSearchTranslations?: ColumnSearchTranslationsType;
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
}

const PeripheralDevices: FC<PeripheralDevicesProps> = ({
  devices = [],
  expandedColumnsTranslations,
  columns,
  loading = false,
}) => {

  const expandedRowRender = ({ sensors }: DeviceModel) => {
    const expandedColumns: TableColumnsType<DeviceSensorModel> = ExpandedPeripheralDevicesColumns(expandedColumnsTranslations);

    return (
      <TableAtom<DeviceSensorModel>
        columns={expandedColumns}
        dataSource={sensors}
        pagination={false}
        rowKey={'sensorId'}
      />
    );
  };

  return (
    <TableAtom<DeviceModel>
      className={'PeripheralDevices__expanded-table-wrapper'}
      loading={loading}
      columns={columns}
      expandable={{ expandedRowRender }}
      rowKey={'deviceId'}
      dataSource={devices}
      pagination={false}
    />
  );
};

export default PeripheralDevices;
