import { FC } from 'react';
import { FormProps } from 'antd/es/form/Form';
import { DeviceTypesType } from '../utils';
import WaterMeterWm1000nbForm, { WaterMeterWm1000nbOptionsModel } from '../WaterMeterWm1000nbForm';

interface DeviceTypesVisorManagerProps extends FormProps {
  formType: DeviceTypesType;
  waterMeterWm1000nbOptions: WaterMeterWm1000nbOptionsModel;
  deviceSectionNumber?: number;
  sensorsSectionNumber?: number;
  loading: boolean;
}

const visorContentManager = {
  [DeviceTypesType.WATERMETER_WM1000NB]: WaterMeterWm1000nbForm,
};

const DeviceTypesVisorManager: FC<DeviceTypesVisorManagerProps> = ({ formType, ...props }) => {
  const Component = visorContentManager[formType];

  return <Component {...props} />;
};

export default DeviceTypesVisorManager;
