import axios, { AxiosRequestConfig } from 'axios';
import { getErrorFormat } from '../../../utils/errors';
import {
  ExternalInvitationsListPayload,
  ExternalInvitationsListResponseModel,
  GetAccessExternalInvitationsListByProjectIdProps,
} from '../models';
import { accessExternalInvitationsListPayloadToModel } from '../mappers';

const getUrl = ({
  host,
  projectId,
  pageNumber,
  pageSize,
  statusList,
}: Omit<GetAccessExternalInvitationsListByProjectIdProps, 'token'>) => {
  const params = new URLSearchParams();
  pageNumber && params.append('page[number]', `${pageNumber}`);
  pageSize && params.append('page[size]', `${pageSize}`);
  pageSize && params.append('page[size]', `${pageSize}`);
  statusList && !!statusList.length && params.append('status', `${statusList.toString()}`);

  return `${host}/access/qr/project/${projectId}?${params}`;
};

export const GetAccessExternalInvitationsListByProjectId = async ({
  host,
  token,
  projectId,
  pageNumber,
  pageSize,
  statusList,
}: GetAccessExternalInvitationsListByProjectIdProps): Promise<ExternalInvitationsListResponseModel> => {
  try {
    const url = getUrl({ host, projectId, pageNumber, pageSize, statusList });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Authorization: token,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept-version': '1',
      },
    };

    const { data } = await axios(options) as { data: ExternalInvitationsListPayload};

    return accessExternalInvitationsListPayloadToModel(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
