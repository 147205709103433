import { FC } from 'react'
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import ModalAtom, { ModalAtomProps } from 'components/atoms/ModalAtom';
import SpaceAtom from 'components/atoms/SpaceAtom';

interface Props extends ModalAtomProps {
  text: string;
  mainButtonText: string;
  secondaryButtonText: string;
  deleting: boolean;
}

const DeleteDeviceWithoutGatewayModal: FC<Props> = ({ title, mainButtonText, secondaryButtonText, text, onCancel, onOk, deleting, ...props }) => {
  return (
    <ModalAtom
      {...props}
      title={<SpaceAtom>{title}</SpaceAtom>}
      onCancel={onCancel}
      destroyOnClose
      width={'50%'}
      footer={[
        <DefaultButton key={'back'} onClick={onCancel} title={secondaryButtonText} />,
        <PrimaryButton key={'submit'} loading={deleting} onClick={onOk} title={mainButtonText} />,
      ]}
    >
      {text}
    </ModalAtom>
  );
}

export default DeleteDeviceWithoutGatewayModal;
