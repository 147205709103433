import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AssetDeviceModel, AssetDevicesPayloadModel } from '../models/assetsModel';
import { payloadToDeviceAsset } from '../mappers/payloadToDeviceAsset';

const getUrl = (host: string, assetId: string): string => {
  return `${host}/assets/${assetId}/devices?type=lock`;
}

export const GetDevicesTypeLockByAssetId = async ({ host, token, assetId }:
  {
    token: string;
    host: string;
    assetId: string;
  }
): Promise<AssetDeviceModel[]> => {
  const url = getUrl(host, assetId);
  const options: AxiosRequestConfig = {
    url,
    method: 'GET',
    timeout: 10000,
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token,
      'Accept-version': '1',
    },
  };
  const { data } = (await axios(options)) as AxiosResponse<{ data: AssetDevicesPayloadModel }>;
  return payloadToDeviceAsset(data.data);
}