import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import {
  TABLE_PAGINATION_PAGE_DEFAULT,
  TABLE_PAGINATION_SIZE_DEFAULT,
  TablePaginationSearchValue
} from 'constants/table';
import {
  externalInvitationItemDefault,
  filtersStatus,
  getDecoratedValidDate,
  getRevokeModalLiterals,
  getValidateModalLiterals,
  literalsColumnsExternalInvitationsList
} from './utils';
import { getExternalInvitationsListWithPaginationPath } from 'components/pages/App/routes/access/config';
import { HotelAccessesTabType } from 'components/pages/AccessesPage/resources/utils';
import { ExternalInvitationsListColumns } from 'components/template/ExternalInvitationsListTemplate/ExternalInvitationsListColumns';
import { messageAtom } from 'components/atoms/MessageAtom';
import { ExternalInvitationModel, ExternalInvitationStatusEnum } from 'core/domain/offices/models';
import { GetAccessExternalInvitationsListByProjectId } from 'core/domain/offices/repository/getAccessExternalInvitationsListByProjectId';
import { RevokeExternalInvitation } from 'core/domain/offices/repository/revokeExternalInvitation';
import { ValidateExternalInvitation } from 'core/domain/offices/repository/validateExternalInvitation';

export const useExternalInvitationsList = () => {
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [externalInvitationsList, setExternalInvitationsList] = useState<ExternalInvitationModel[]>([]);
  const [selectedExternalInvitationItem, setSelectedExternalInvitationItem] =
    useState<ExternalInvitationModel>(externalInvitationItemDefault);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingRevoke, setIsLoadingRevoke] = useState<boolean>(false);
  const [isLoadingValidate, setIsLoadingValidate] = useState<boolean>(false);
  const [isVisibleRevokeModal, setIsVisibleRevokeModal] = useState<boolean>(false);
  const [isVisibleValidateModal, setIsVisibleValidateModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [totalPages, setTotalPages] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [selectedStatus, setSelectedStatus] = useState<ExternalInvitationStatusEnum[] | null>(null);

  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);

  const onSelectedExternalInvitationItem = (id: string) => {
    setSelectedExternalInvitationItem(externalInvitationsList.find(item => item.id === id) || externalInvitationItemDefault);
  };

  const onChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize!);
    history.push(getExternalInvitationsListWithPaginationPath({ clientId, projectId, page: String(page), size: String(pageSize!) }));
  };

  const getData = ({ page, size, statusList }: { page: number; size: number, statusList?: ExternalInvitationStatusEnum[] }): void => {
    GetAccessExternalInvitationsListByProjectId({
      host,
      token,
      projectId,
      pageNumber: page,
      pageSize: size,
      statusList,
    })
      .then(({ data, meta }) => {
        setExternalInvitationsList(data);
        setTotalPages(meta.totalPages);
      })
      .catch(() => setMessageError({ description: t('_ACCESSES_OFFICE_ERROR_MESSAGE') }))
      .finally(() => setIsLoading(false));
  };

  const onRevoke = async (id: string) => {
    setIsLoadingRevoke(true);
    try {
      await RevokeExternalInvitation({
        accessId: id,
        token,
        host
      });
      const parsedPage = page ? Number(page) : Number(TABLE_PAGINATION_PAGE_DEFAULT);
      const parsedSize = size ? Number(size) : Number(TABLE_PAGINATION_SIZE_DEFAULT);
      getData({ page: parsedPage, size: parsedSize });
      messageAtom.success(t('_ACCESSES_OFFICE_REVOKE_MESSAGE_SUCCESS'));
      setIsVisibleRevokeModal(false);
    } catch (error) {
      setMessageError({ description: t('_ACCESSES_OFFICE_REVOKE_MESSAGE_ERROR') });
    } finally {
      setIsLoadingRevoke(false);
    }
  };

  const onValidate = async (id: string) => {
    setIsLoadingValidate(true);
    try {
      await ValidateExternalInvitation({
        accessId: id,
        token,
        host
      });
      const parsedPage = page ? Number(page) : Number(TABLE_PAGINATION_PAGE_DEFAULT);
      const parsedSize = size ? Number(size) : Number(TABLE_PAGINATION_SIZE_DEFAULT);
      await getData({ page: parsedPage, size: parsedSize });
      messageAtom.success(t('_ACCESSES_OFFICE_VALIDATE_MESSAGE_SUCCESS'));
      setIsVisibleValidateModal(false);
    } catch (error: any) {
      if (error.code === 410) {
        const parsedPage = page ? Number(page) : Number(TABLE_PAGINATION_PAGE_DEFAULT);
        const parsedSize = size ? Number(size) : Number(TABLE_PAGINATION_SIZE_DEFAULT);
        await getData({ page: parsedPage, size: parsedSize });
        setMessageError({ description: t('_ACCESSES_OFFICE_VALIDATE_MESSAGE_ERROR_EXPIRED') }, 10);
        setIsVisibleValidateModal(false);
      }

      if (error.code !== 410) {
        setMessageError({ description: t('_ACCESSES_OFFICE_VALIDATE_MESSAGE_ERROR') });
      }
    } finally {
      setIsLoadingValidate(false);

    }
  };

  const onRevokeFromColumn = (id: string) => {
    setIsLoading(true);
    onSelectedExternalInvitationItem(id);
    setIsVisibleRevokeModal(true);
  };
  
  const onValidateFromColumn = (id: string) => {
    setIsLoading(true);
    onSelectedExternalInvitationItem(id);
    setIsVisibleValidateModal(true);
  };

  const columnsExternalInvitationsList = ExternalInvitationsListColumns({
    ...literalsColumnsExternalInvitationsList,
    filtersStatus,
    onRevoke: onRevokeFromColumn,
    onValidate: onValidateFromColumn,
  });

  useEffect(() => {
    const parsedPage = page ? Number(page) : Number(TABLE_PAGINATION_PAGE_DEFAULT);
    const parsedSize = size ? Number(size) : Number(TABLE_PAGINATION_SIZE_DEFAULT);

    setCurrentPage(parsedPage);
    setPageSize(parsedSize);
    setIsLoading(true);
    getData({ page: parsedPage, size: parsedSize, statusList: selectedStatus ?? [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, selectedStatus]);

  useEffect(() => {
    const tab = window.location.pathname.split('/')[8];
    if (tab === HotelAccessesTabType.CHECK_IN_LIST) {
      const parsedPage = page ? Number(page) : Number(TABLE_PAGINATION_PAGE_DEFAULT);
      const parsedSize = size ? Number(size) : Number(TABLE_PAGINATION_SIZE_DEFAULT);
      setIsLoading(true);
      getData({ page: parsedPage, size: parsedSize });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const onAcceptRevokeModal = async () => {
    setIsLoadingRevoke(true);
    try {
      await onRevoke(selectedExternalInvitationItem.id);
    } catch (error) {
      setMessageError({ description: t('_ACCESSES_OFFICE_REVOKE_MESSAGE_ERROR') });
    } finally {
      setIsLoadingRevoke(false);
    }
  };

  const onCancelRevokeModal = () => {
    setIsLoadingRevoke(false);
    setIsLoading(false);
    setIsVisibleRevokeModal(false);
  };

  const guestNameText = selectedExternalInvitationItem.nameSurname;
  const validFromText = getDecoratedValidDate(selectedExternalInvitationItem.validFrom);
  const validUntilText = getDecoratedValidDate(selectedExternalInvitationItem.validUntil);

  const revokeModalLiterals = getRevokeModalLiterals({
    guestName: guestNameText,
    validFrom: validFromText,
    validUntil: validUntilText,
  });

  const onAcceptValidateModal = async () => {
    setIsLoadingValidate(true);
    try {
      await onValidate(selectedExternalInvitationItem.id);
    } catch (error) {
      setMessageError({ description: t('_ACCESSES_OFFICE_VALIDATE_MESSAGE_ERROR') });
    } finally {
      setIsLoadingValidate(false);
    }
  };

  const onCancelValidateModal = () => {
    setIsLoadingValidate(false);
    setIsLoading(false);
    setIsVisibleValidateModal(false);
  };

  const validateModalLiterals = getValidateModalLiterals({
    guestName: guestNameText,
    validFrom: validFromText,
    validUntil: validUntilText,
  });

  const onFilter = (status: ExternalInvitationStatusEnum[] | null): void => {
    setSelectedStatus(status);
  }

  return {
    revokeModalLiterals,
    validateModalLiterals,
    externalInvitationsList,
    totalPages,
    currentPage,
    pageSize,
    columnsExternalInvitationsList,
    onChangePage,
    onAcceptRevokeModal,
    onCancelRevokeModal,
    onAcceptValidateModal,
    onCancelValidateModal,
    onFilter,
    isLoading,
    isLoadingRevoke,
    isVisibleRevokeModal,
    isLoadingValidate,
    isVisibleValidateModal,
  };
};
