import { AssetDeviceModel, AssetDevicesPayloadModel, DevicePayloadModel } from "../models/assetsModel";

const devicesPayloadToDeviceModel = ({
  available_actions,
  battery_level,
  device_id,
  device_name,
  device_type,
  enabled,
  parameters,
  password,
  sensors,
  thing_id,
  lock_type,
  thing_type_uid,
}: DevicePayloadModel, gatewayId: string): AssetDeviceModel => {
  return {
    gatewayId,
    availableActions: available_actions,
    batteryLevel: battery_level,
    deviceId: device_id,
    deviceName: device_name ?? '',
    deviceType: device_type ?? '',
    enabled,
    parameters,
    password,
    sensors,
    thingId: thing_id,
    thingTypeUid: thing_type_uid,
    lockType: lock_type
  }
}

export const payloadToDeviceAsset = (response?: AssetDevicesPayloadModel): AssetDeviceModel[] => {
  return response
    ? response.gateways.flatMap(({ devices, uuid }) => {
      return devices.map(device => devicesPayloadToDeviceModel(device, uuid));
    })
    : [];

}