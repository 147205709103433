import { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import TabsTitles from './Tabs/TabsTitles/';
import TabUsers from './Tabs/TabUsers';
import TabStart from './Tabs/TabStart';
import TabDevices from './Tabs/TabDevices';
import TabEvents from './Tabs/TabEvents';
import TabAccesses from './Tabs/TabAccesses';
import TabDocuments from './Tabs/TabDocuments';
import AlfredDetailTemplate from 'components/template/AlfredDetailTemplate';
import HeaderDescription from './HeaderDescription';
import { useAsset } from 'hooks/useAsset';
import AssetMenuExtra from 'components/organisms/AssetMenuExtra';
import TabUsefulContacts from './Tabs/TabUsefulContacts';
import TabCameras from './Tabs/TabCameras';
import { RegisterAssetAttributes } from 'models/assets.model';
import { getGatewayStatusGID } from 'services/api/gateways';
import TabConsumption from './Tabs/TabConsumption';
import TabGateway from './Tabs/TabGateway';
import './styles.scss';

const AssetPage: FC = () => {
  const { assetId, tab } = useParams<{ assetId: string; tab: string }>();
  const { t } = useTranslation();
  const {
    asset,
    project,
    client,
    deleteModalTitle,
    assetGatewayConnectionStatusTranslations,
    onDeleteAsset,
    onClickDelete,
    onCancelDelete,
    onClickAgree,
    editAsset,
    onClickClientLink,
    onClickProjectLink,
    isLoading,
    isLoadingUpdate,
    isLoadingDelete,
    showActiveUsersInfoModal,
    showLinkedGatewaysInfoModal,
    showConfirmDeleteModal,
    hasPermissionToDeleteAsset,
  } = useAsset();
  const [gatewayId, setGatewayId] = useState<string>('');
  const history = useHistory();

  const getPageTitle = () => {
    if (asset?.alias) return asset.alias;
    return `${asset?.street_type} ${asset?.street_name} ${asset?.block_number}, ${asset?.apartment}`;
  };

  const handleOnClick = (activeTab: string) => {
    const parsedActiveTab = activeTab.replace('_', '-');
    history.replace(parsedActiveTab);
  };

  const handleOnSubmitEdit = async (values: RegisterAssetAttributes) => {
    await editAsset(values);
  };

  const handleOnDelete = async () => {
    await onDeleteAsset();
  }

  useEffect(() => {
    getGatewayStatusGID(assetId).then(({ gatewayId, gatewayQid }) => {
      setGatewayId(gatewayId);
    });
  }, [assetId]);

  return (
    <AlfredDetailTemplate
      currentTabKey={tab}
      title={getPageTitle()}
      loading={isLoading}
      extra={[
        asset.is_blocked && <Tag>{t('_BLOCKED')}</Tag>,
        asset.id && (
          <AssetMenuExtra
            key={'AssetMenuExtra'}
            asset={asset}
            gatewayId={gatewayId}
            deleteModalTitle={deleteModalTitle}
            onDeleteAsset={handleOnDelete}
            onSubmitEdit={handleOnSubmitEdit}
            onClickDelete={onClickDelete}
            onCancelDelete={onCancelDelete}
            onClickAgree={onClickAgree}
            isEditLoading={isLoadingUpdate}
            isDeleteLoading={isLoadingDelete}
            showActiveUsersInfoModal={showActiveUsersInfoModal}
            showLinkedGatewaysInfoModal={showLinkedGatewaysInfoModal}
            deleteDisabled={!hasPermissionToDeleteAsset}
            maintenanceDisabled={!gatewayId}
            showConfirmDeleteModal={showConfirmDeleteModal}
          />
        ),
      ]}
      description={asset && (
        <HeaderDescription
          assetId={assetId}
          asset={asset}
          client={client}
          project={project}
          assetGatewayConnectionStatusTranslations={assetGatewayConnectionStatusTranslations}
          onClickClientLink={onClickClientLink}
          onClickProjectLink={onClickProjectLink}
        />)}
      tabsTitles={(currentTab) => <TabsTitles onChange={handleOnClick} activeKey={currentTab} />}
    >
      {[
        { key: 'start', render: <TabStart key={'start'} /> },
        { key: 'users', render: <TabUsers key={'users'} /> },
        { key: 'devices', render: <TabDevices key={'devices'} /> },
        { key: 'gateway', render: <TabGateway key={'gateway'} /> },
        { key: 'consumptions', render: <TabConsumption key={'consumptions'} assetId={assetId} /> },
        { key: 'cameras', render: <TabCameras key={'cameras'} /> },
        { key: 'events', render: <TabEvents key={'events'} /> },
        { key: 'accesses', render: <TabAccesses key={'accesses'} /> },
        {
          key: 'documents',
          render: <TabDocuments key={'documents'} projectId={!!asset.project ? asset.project[0].id : undefined} />,
        },
        { key: 'useful-contacts', render: <TabUsefulContacts key={'useful_contacts'} assetId={assetId} /> },
      ]}
    </AlfredDetailTemplate>
  );
};

export default AssetPage;
