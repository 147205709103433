import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CreateDeviceLiteralsType } from 'components/organisms/CreateDeviceWithoutGatewayDrawer';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { DeviceTypeWithoutGatewayDataModel, DeviceWithoutGatewayCreateModel } from 'core/domain/devices/models';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import { MessageErrorModel, useMessage } from 'hooks/useMessage';
import { GetDevicesTypesListWithoutGateway } from 'core/domain/devices/repositories/getDevicesTypesListWithoutGateway';
import { useWaterMeterWm1000nb } from './useWaterMeterWm1000nb';
import { WaterMeterWm1000nbOptionsModel } from './WaterMeterWm1000nbForm';
import { CreateDeviceDetailWithoutGateway } from 'core/domain/devices/repositories/createDeviceWithoutGateway';
import { DeviceTypesType } from './utils';

enum CreateDeviceErrorCodeType {
  ALREADY_EXISTS = 400,
  SECRET_KEY_REQUIRED = 422,
}

export interface CreateDeviceOptionsModel {
  createDeviceLiterals: CreateDeviceLiteralsType;
  devicesTypes: DeviceTypeWithoutGatewayDataModel[];
  formType: DeviceTypesType | null;
  waterMeterWm1000nbOptions: WaterMeterWm1000nbOptionsModel;
  onOpenCreateDeviceDrawer: () => void;
  onCloseCreateDeviceDrawer: () => void;
  onChangeDeviceType: (value: string) => void;
  onAddDevice: () => void;
  createDeviceDrawerVisible: boolean;
  loading: boolean;
  hasDevicesTypes: boolean;
  creating: boolean;
  submitButtonDisabled: boolean;
}

export const useCreateDeviceWithoutGateway = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [devicesTypes, setDevicesTypes] = useState<DeviceTypeWithoutGatewayDataModel[]>([]);
  const [formType, setFormType] = useState<DeviceTypesType | null>(null);
  const [creating, setCreating] = useState<boolean>(false);
  const [successCreatingDevice, setSuccessCreatingDevice] = useState<boolean>(false);
  const [isSubmitButtonDisable, setIsSubmitButtonDisabled] = useState<boolean>(true);
  const { waterMeterWm1000nbOptions, waterMeterWm1000nbParameters, resetWaterMeterWm1000nbValues, errorCreatingWaterMeterWm1000nb } =
    useWaterMeterWm1000nb();

  const hasDevicesTypes = !!devicesTypes.length;

  const literals: CreateDeviceLiteralsType = {
    createDeviceButtonText: t('add'),
    title: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_TITLE'),
    typeSectionTitle: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_TITLE'),
    mainButtonText: t('add'),
    secondaryButtonText: t('cancel'),
    infoText: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_INFO_MESSAGE'),
    deviceTypePlaceholder: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_DEVICES_TYPES_PLACEHOLDER'),
    deviceTypeLabel: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_DEVICES_TYPES_LABEL'),
  };

  const onOpenCreateDeviceDrawer = () => {
    setDrawerVisible(true);
  };

  const resetValues = () => {
    setFormType(null);
    setCreating(false);
    resetWaterMeterWm1000nbValues();
    setIsSubmitButtonDisabled(true);
  };

  const onCloseCreateDeviceDrawer = () => {
    resetValues();
    setDrawerVisible(false);
  };

  const onChangeDeviceType = (value: string) => {
    setFormType(value as DeviceTypesType);
    setIsSubmitButtonDisabled(false);
  };

  const addDeviceManager = {
    [DeviceTypesType.WATERMETER_WM1000NB]: () => waterMeterWm1000nbOptions.form.submit(),
  };

  const onAddDevice = () => {
    !!formType && addDeviceManager[formType]();
    setCreating(true);
  };

  const getDevicesTypes = async () => {
    try {
      setLoading(true);
      const { data } = await GetDevicesTypesListWithoutGateway({
        host,
        token,
        pageNumber: Number(TABLE_PAGINATION_PAGE_DEFAULT),
        pageSize: Number(TABLE_PAGINATION_SIZE_DEFAULT),
      });
      setDevicesTypes(data);
    } catch (error) {
      setMessageError({ description: t('_CREATE_DEVICE_WITHOUT_GATEWAY_DRAWER_DEVICES_TYPES_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  const completeDeviceData = (device: DeviceWithoutGatewayCreateModel): DeviceWithoutGatewayCreateModel => {
    const deviceType = formType || '';
    return { ...device, assetId, deviceType };
  };

  const controlledErrorDescriptionManager = {
    [CreateDeviceErrorCodeType.ALREADY_EXISTS]: t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_400_MESSAGE'),
    [CreateDeviceErrorCodeType.SECRET_KEY_REQUIRED]: t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_422_MESSAGE'),
  };

  const checkErrors = (error: MessageErrorModel) => {
    const controlledErrors = Object.values(CreateDeviceErrorCodeType);
    const isControlledError = controlledErrors.includes(error.code);
    const description = isControlledError
      ? controlledErrorDescriptionManager[error.code as CreateDeviceErrorCodeType]
      : t('_CREATE_DEVICE_WITHOUT_GATEWAY_ERROR_MESSAGE');

    setMessageError({ description });
  };

  const createDevice = async (device: DeviceWithoutGatewayCreateModel) => {
    try {
      const completedDeviceData = completeDeviceData(device);
      await CreateDeviceDetailWithoutGateway({ host, token, device: completedDeviceData });
      setMessageSuccess({ description: t('_CREATE_DEVICE_WITHOUT_GATEWAY_SUCCESS_MESSAGE') });
      setSuccessCreatingDevice(true);
      onCloseCreateDeviceDrawer();
    } catch (error) {
      checkErrors(error as MessageErrorModel);
    } finally {
      setCreating(false);
      setSuccessCreatingDevice(false);
    }
  };

  const createDeviceOptions: CreateDeviceOptionsModel = {
    createDeviceLiterals: literals,
    devicesTypes,
    formType,
    waterMeterWm1000nbOptions,
    onOpenCreateDeviceDrawer,
    onCloseCreateDeviceDrawer,
    onChangeDeviceType,
    onAddDevice,
    createDeviceDrawerVisible: drawerVisible,
    loading,
    hasDevicesTypes,
    creating,
    submitButtonDisabled: isSubmitButtonDisable,
  };

  useEffect(() => {
    getDevicesTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !!waterMeterWm1000nbParameters && createDevice(waterMeterWm1000nbParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterMeterWm1000nbParameters]);

  useEffect(() => {
    !!errorCreatingWaterMeterWm1000nb && setCreating(false);
  }, [errorCreatingWaterMeterWm1000nb]);

  return { createDeviceOptions, successCreatingDevice };
};
