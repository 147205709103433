import { FC } from 'react';
import TagSuccess from 'components/atoms/tags/TagSuccess';
import TagWarning from 'components/atoms/tags/TagWarning';
import TagError from 'components/atoms/tags/TagError';
import TagDefault from 'components/atoms/tags/TagDefault';
import { ExternalInvitationStatusEnum } from 'core/domain/offices/models';

export interface Props {
  status: ExternalInvitationStatusEnum;
  text: string;
}

const statusTagManager = {
  [ExternalInvitationStatusEnum.PENDING]: (text: string) => <TagDefault text={text} />,
  [ExternalInvitationStatusEnum.VALIDATED]: (text: string) => <TagSuccess text={text} />,
  [ExternalInvitationStatusEnum.REVOKED]: (text: string) => <TagError text={text} />,
  [ExternalInvitationStatusEnum.EXPIRED]: (text: string) => <TagWarning text={text} />,
}

const ExternalInvitationsListStatusTagManager: FC<Props> = ({ status, text }) => {
  try {
    return statusTagManager[status](text);
  } catch (error) {
    return <TagDefault text={status} />
  }
}

export default ExternalInvitationsListStatusTagManager
