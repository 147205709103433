import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SensorWithPasswordsModel } from 'core/domain/sensors/models/sensorEntityModels';
import { GetSensorsListWithPasswordsByAssetId } from 'core/domain/sensors/repositories/getSensorsListWithPasswordsByAssetId';
import { useParams } from 'react-router-dom';
import { AccessDeviceEditTableModel } from '../Access/AccessColumn';
import { GetDevicesTypeLockByAssetId } from 'core/domain/assets/repositories/getDevicesTypeLockByAssetId';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { AssetDeviceModel } from 'core/domain/assets/models/assetsModel';
import { useMessage } from 'hooks/useMessage';

const deviceListToAccessList = (
  devicePasswordList: SensorWithPasswordsModel[],
  deviceList: AssetDeviceModel[]
): AccessDeviceEditTableModel[] => {
  return deviceList.map((device) => {
    const currentDevicePassword = devicePasswordList.find((devicePassword) => {
      return devicePassword.deviceId === device.deviceId && devicePassword.gatewayId && device.gatewayId;
    });
    return {
      parameterName: device.parameters.name || '',
      parameterRoom: device.parameters.room || '',
      deviceName: device.deviceName,
      deviceType: currentDevicePassword?.deviceType || device.deviceType,
      deviceGatewayId: currentDevicePassword?.sensorPasswords[0].gatewayId || device.gatewayId,
      deviceId: currentDevicePassword?.sensorPasswords[0].deviceId || device.deviceId,
      deviceParameters: currentDevicePassword?.deviceParameters || {
        maxDigits: '',
        minDigits: '',
        validDigits: '',
      },
      sensorPasswords: currentDevicePassword?.sensorPasswords || [],
      hasPasswords: !!currentDevicePassword?.sensorPasswords.length,
    };
  });
};

export const useAccessDeviceEdit = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [deviceList, setDeviceList] = useState<AccessDeviceEditTableModel[]>([]);
  const [currentDevice, setCurrentDevice] = useState<SensorWithPasswordsModel>({} as SensorWithPasswordsModel);

  const getData = async (): Promise<AccessDeviceEditTableModel[]> => {
    try {
      const devicesWithPasswordList = await GetSensorsListWithPasswordsByAssetId(assetId);
      const accessDevicesList = await GetDevicesTypeLockByAssetId({ host, token, assetId });
      const devices = deviceListToAccessList(devicesWithPasswordList, accessDevicesList);
      setDeviceList(devices);
      return devices;
    } catch (error) {
      setMessageError({ description: t('_ASSET_ACCESS_DEVICE_LIST_ERROR_MESSAGE') });
      return [];
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCurrentDevice = (device: SensorWithPasswordsModel) => setCurrentDevice(device);

  return {
    deviceList,
    currentDevice,
    getDeviceList: getData,
    onCurrentDevice,
    loading,
  };
};
