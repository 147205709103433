import Paper from 'components/atoms/Paper';
import { Menu } from 'antd';
import AlfredTableTemplate from 'components/template/AlfredTableTemplate';
import { useProjectAssets } from './useProjectAssets';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';
import { ProjectAssetsColumns } from 'components/organisms/ProjectAssetColumns';
import ContextualMenu from 'components/atoms/ContextualMenu';
import TextLinkAtom from 'components/atoms/TextLinkAtom';
import AssignUserToMultipleAssetsModal from 'components/molecules/AssignUserToMultipleAssetsModal';
import AddUserModalContentSearch from './addUserModalContentSearch';
import AddUserModalContentResponse from './addUserModalContentResponse';
import './styles.scss';

const TabAssets = () => {
  const {
    assets,
    currentPage,
    pageSize,
    totalPages,
    assetGatewayConnectionStatusTranslations,
    assetOccupationTranslations,
    contextualMenuAddUserText,
    rowSelection,
    allResultsText,
    selectedAssetsText,
    addUserText,
    cancelText,
    addText,
    searchUserModalPlaceholder,
    selectedUser,
    modalBoxText,
    failedAssetsData,
    errorModalBoxText,
    checkedAssetList,
    checkedAssetIdList,
    onAddUser,
    onCancel,
    onAddMassiveUsers,
    onChangePage,
    onOpenLink,
    onSearch,
    onSelectAll,
    onSelectAsset,
    onSearchUser,
    onSelectUser,
    isLoading,
    isVisibleAddUserModal,
    isHiddenAddButton,
    isDisabledCancelButton,
    isLoadingWhileAddUsers,
    isDisabledAddButton,
    isSearchInputOpen,
  } = useProjectAssets();

  const { assetNotOccupied, assetOccupied } = assetOccupationTranslations;
  const { connected, disconnected, noLinkedGateways, defaultText } = assetGatewayConnectionStatusTranslations;
  const assetsColumns = ProjectAssetsColumns({
    onClick: onSelectAsset,
    onOpenLink,
    assetOccupiedText: assetOccupied,
    assetNotOccupiedText: assetNotOccupied,
    assetConnectedText: connected,
    assetDisconnectedText: disconnected,
    assetNoLinkedGatewayText: noLinkedGateways,
    assetDefaultText: defaultText,
  });

  const RightMenu = () => (
    <div className={'TabAssets__contextual-menu'}>
      <ContextualMenu menu={
        <Menu>
          <Menu.Item
            key={'addUserToMassiveAssetsList'}
            onClick={onAddMassiveUsers}
            disabled={!checkedAssetIdList.length}
          >
            {contextualMenuAddUserText}
          </Menu.Item>
        </Menu>
      } />
    </div>
  );

  const LeftActionSection = () => (
    <div className={'TabAssets__left-action-section'}>
      {`${selectedAssetsText} `}
      <TextLinkAtom
        onClick={onSelectAll}
      >
        {allResultsText}
      </TextLinkAtom>
    </div>
  );

  return (
    <Paper>
      <AlfredTableTemplate<ProjectAssetModel>
        actionSection={
          checkedAssetIdList.length
            ? <LeftActionSection />
            : <></>
        }
        actionSectionRight={<RightMenu />}
        columns={assetsColumns}
        data={assets}
        pageCurrent={currentPage}
        pageSize={pageSize}
        rowSelection={rowSelection}
        totalPages={totalPages}
        onPagination={onChangePage}
        onRowClick={onSelectAsset}
        onSearch={onSearch}
        isCharging={isLoading}
      />
      <AssignUserToMultipleAssetsModal
        title={addUserText}
        cancelText={cancelText}
        addText={addText}
        onAddUser={onAddUser}
        onCancel={onCancel}
        isLoadingAdd={isLoadingWhileAddUsers}
        isHiddenAddButton={isHiddenAddButton}
        open={isVisibleAddUserModal}
        isDisabledCancelButton={isDisabledCancelButton}
        isDisabledAddButton={isDisabledAddButton}
        isScroll={failedAssetsData.length > 6}
        width={700}
      >
        {!failedAssetsData.length && (
          <AddUserModalContentSearch
            searchUserModalPlaceholder={searchUserModalPlaceholder}
            checkedAssetList={checkedAssetList}
            selectedUser={selectedUser}
            boxText={modalBoxText}
            onSearchUser={onSearchUser}
            onSelectUser={onSelectUser}
            isSearchInputOpen={isSearchInputOpen}
          />
        )}
        {!!failedAssetsData.length && (
          <AddUserModalContentResponse
            text={errorModalBoxText}
            list={failedAssetsData}
          />
        )}
      </AssignUserToMultipleAssetsModal>
    </Paper >
  );
};

export default TabAssets;
