import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import AlfredTableTemplate from '../AlfredTableTemplate';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { ExternalInvitationModel, ExternalInvitationStatusEnum } from 'core/domain/offices/models';
import './styles.scss';

interface Props {
  externalInvitationsList: ExternalInvitationModel[];
  totalPages: number;
  currentPage: number;
  pageSize: number;
  columns: AlfredTableColumn<ExternalInvitationModel>[]
  onChangePage: (page: number, pageSize?: number | undefined) => void;
  onFilter: (status: ExternalInvitationStatusEnum[] | null) => void;
  loading: boolean;
}

const ExternalInvitationsListTemplate: FC<Props> = ({
  columns,
  externalInvitationsList,
  totalPages,
  currentPage,
  pageSize,
  onChangePage,
  onFilter,
  loading,
}) => {
  return (
    <Paper className={'ExternalInvitationsListTemplate__wrapper'}>
      <AlfredTableTemplate<ExternalInvitationModel>
        columns={columns}
        data={externalInvitationsList}
        totalPages={totalPages}
        pageCurrent={currentPage}
        pageSize={pageSize}
        onPagination={onChangePage}
        onChange={({ filters }) => {
          const { status } = filters as { status: ExternalInvitationStatusEnum[] | null };
          onFilter(status)
        }}
        isCharging={loading}
      />
    </Paper>
  )
}

export default ExternalInvitationsListTemplate;