import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import { GetDevicesLists } from 'core/domain/devices/repositories/getDevicesList';
import { DeviceModel } from 'core/domain/devices/models';
import { ExpandedPeripheralDevicesTranslations } from '../PeripheralDevices/ExpandedColumnsPeripheraldevices';
import { ColumnSearchTranslationsType, DeviceDataIndex } from '../PeripheralDevices/SearchPeripheralColumns';
import { useShareSensor } from './useShareSensor';

export const useTabDevices = () => {
  const { t } = useTranslation();
  const { gatewayId } = useParams<{ projectId: string; gatewayId: string }>();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<any>(null);
  const { shareSensorOptions } = useShareSensor();

  const peripheralExpandedColumnsTranslations: ExpandedPeripheralDevicesTranslations = {
    nameText: t('name'),
    roomText: t('room'),
    typeText: t('type'),
    usageText: t('usage'),
    actionsTitle: t('actions'),
    shareSensorText: t('_SHARE_SENSOR_TEXT'),
    notReportedText: t('not_reported'),
  };

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
    notReportedText: t('not_reported'),
  };

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const getDevices = async () => {
    setIsLoading(true);
    try {
      const response = await GetDevicesLists({ host, token, gatewayId });
      setDevices(response);
    } catch (error) {
      setMessageError({ description: t('_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    devices,
    gatewayId,
    peripheralExpandedColumnsTranslations,
    searchInput,
    columnSearchTranslations,
    searchedColumn,
    shareSensorOptions,
    onSearch,
    onReset,
    loading: isLoading,
  };
};
