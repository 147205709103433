import { User } from 'models/users.model';

export interface AssetsOccupationModel {
  assetsLinkedToUsers: number;
  assetsNotLinkedToUsers: number;
  totalAssets: number;
}

export interface AssetGatewaysQrsModel {
  gatewayId: string;
  qrCode: string;
}

export enum AssetGatewayConnectionStatusType {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  NO_LINKED_GATEWAY = 'no_linked_gateway',
  DEFAULT = 'not_reported',
}

export interface AssetModel {
  id: string;
  alias: string;
  country: string;
  state: string;
  city: string;
  type: string;
  zipCode: string;
  streetType: string;
  streetName: string;
  blockNumber: string;
  staircase: string;
  logo: string;
  flat: string;
  door: string;
  createdAt: number;
  updatedAt: number;
  location: string;
  apartment: string;
  assetQr: string;
  projects: { id: string }[];
  project?: string;
  description: string;
  gatewaysQrs: AssetGatewaysQrsModel[];
  isOccupied: boolean;
  gatewayConnectionStatus: string | null;
  links?: {
    self: string;
  };
}

export interface ProjectAssetIdModel {
  id: string;
}

export interface AssetIncludedModel {
  projects: ProjectAssetIdModel;
  users: User[];
}

export interface AssetResponseModel {
  data: AssetModel;
  included: AssetIncludedModel;
}

export enum MaintenanceAction {
  BATTERY_REPLACEMENT = 'battery_replacement',
  REPLACEMENT = 'replacement',
  ADDED_DEVICE = 'added_device',
  REMOVED_DEVICE = 'removed_device',
  SOFTWARE_UPDATE = 'software_update',
  CLEANING = 'cleaning',
  ON_SITE_CHECK = 'on_site_check',
  REMOTE_CHECK = 'remote_check',
  CALL_ATTENDED = 'call_attended',
  MANAGEMENT_PASSWORD = 'management_password',
}

export interface DeviceParameters {
  [key: string]: string;
}

export interface DeviceSensorPayloadModel {
  sensor_id: string;
  item_name: string;
  sensor_type: string;
  item_type: string;
  room?: string;
  usage?: string;
  name?: string;
}

export interface DevicePayloadModel {
  available_actions: MaintenanceAction[];
  device_id: string;
  device_type: string | null;
  device_name: string | null;
  enabled: boolean;
  lock_type?: 'public' | 'private';
  parameters: DeviceParameters;
  password: string;
  sensors: DeviceSensorPayloadModel[];
  thing_id: string;
  thing_type_uid?: string;
  battery_level: number | null;
}


export interface AssetDevicesPayloadModel {
    gateways: {
      uuid: string;
      devices: DevicePayloadModel[];
  }[];
}

export interface AssetDeviceModel {
  gatewayId: string;
  availableActions: MaintenanceAction[];
  deviceId: string;
  deviceType: string;
  deviceName: string;
  enabled: boolean;
  lockType?: 'public' | 'private';
  parameters: DeviceParameters;
  password: string;
  sensors: DeviceSensorPayloadModel[];
  thingId: string;
  thingTypeUid?: string;
  batteryLevel: number | null;
}