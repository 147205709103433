import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { MaintenanceAction } from 'core/domain/gateways/model/reportMaintenance';
import { SensorWithPasswordsModel, SensorWithPasswordsPasswordModel } from 'core/domain/sensors/models/sensorEntityModels';
import { UpdatePasswordSensor } from 'core/domain/sensors/repositories/updatePasswordSensor';
import { GetSensorsListWithPasswordsByAssetId } from 'core/domain/sensors/repositories/getSensorsListWithPasswordsByAssetId';

const TIME_DELAY_TO_UPDATE_PASSWORD_SENSOR = 250;
const TIME_DELAY_TO_UPDATE_ALL_PASSWORDS_SENSORS = 5000;
const TIME_DELAY_TO_RESET_ERROR_UPDATE = 500;
const TIME_MESSAGE = 3;

interface checkingSensorsResponse {
  successUpdating: boolean;
  errorSensors: SensorWithPasswordsPasswordModel[];
}

export const useAccessUpdate = () => {
  const { t } = useTranslation();
  const { assetId } = useParams<{ assetId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deviceId, setDeviceId] = useState<string>('');
  const [isErrorSendingUpdate, setIsErrorSendingUpdate] = useState<boolean>(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean | null>(null);
  const [isUpdateProcessFinished, setIsUpdateProcessFinished] = useState<boolean | null>(null);
  const [counter, setCounter] = useState<number>(0);
  const [errorSensorsIds, setErrorSensorsIds] = useState<string[]>([]);

  const counterRef = useRef<number>(counter);
  const intervalRef = useRef<number | null>(null);
  const timeoutUpdatingIds: NodeJS.Timeout[] = [];

  const updateSuccessMessage = t('_ACCESS_PASSWORDS_UPDATE_SUCCESS_MESSAGE');

  const getDeviceId = (deviceId: string) => {
    setDeviceId(deviceId);
  };

  const resetUpdateStatusRequest = () => {
    setIsUpdateSuccess(null);
    setIsUpdateProcessFinished(null);
    setIsLoading(false);
    setCounter(0);
    intervalRef.current && clearInterval(intervalRef.current);
  };

  const updatePasswordSensor = async (sensor: SensorWithPasswordsPasswordModel) => {
    const { gatewayId, deviceId, password, sensorId } = sensor;
    try {
      await UpdatePasswordSensor({ deviceId, gatewayId, action: MaintenanceAction.MANAGEMENT_PASSWORD, password, sensorId });
    } catch (error: any) {
      setIsErrorSendingUpdate(true);
      timeoutUpdatingIds.forEach((id) => clearTimeout(id));
      intervalRef.current && clearInterval(intervalRef.current);
      setIsLoading(false);
      setTimeout(() => setIsErrorSendingUpdate(false), TIME_DELAY_TO_RESET_ERROR_UPDATE);
    }
  };

  const updateMultiplePasswordSensors = async (sensors: SensorWithPasswordsPasswordModel[]) => {
    sensors.forEach((sensor, index) => {
      if (!isErrorSendingUpdate) {
        const delay = index * TIME_DELAY_TO_UPDATE_PASSWORD_SENSOR;
        const timeoutUpdatePasswordId = setTimeout(async () => {
          await updatePasswordSensor(sensor);
        }, delay);
        timeoutUpdatingIds.push(timeoutUpdatePasswordId);
      }
    });
  };

  const getDevicesWithPasswordSensorsList = async (): Promise<SensorWithPasswordsModel[] | undefined> => {
    try {
      const devicesWithPasswordSensors = await GetSensorsListWithPasswordsByAssetId(assetId);
      return devicesWithPasswordSensors;
    } catch (error) {
      console.warn(error);
    }
  };

  const checkNotEqualSensors = (selectedDevice: SensorWithPasswordsModel, oldSensors: SensorWithPasswordsPasswordModel[]) => {
    const notEqualSensors: SensorWithPasswordsPasswordModel[] = [];
    selectedDevice.sensorPasswords.forEach((sensor) => {
      const oldSensor = oldSensors.find((oldSensor) => oldSensor.sensorId === sensor.sensorId);
      oldSensor && oldSensor.password !== sensor.password && notEqualSensors.push(oldSensor);
    });

    return notEqualSensors;
  };

  const getErrorMessageWithSensors = (errorSensors: SensorWithPasswordsPasswordModel[]) => {
    const currentErrorSensorsIds: string[] = [];
    errorSensors.forEach((sensor) => errorSensorsIds.push(`${t('password')} ${sensor.sensorId}`));
    setErrorSensorsIds(currentErrorSensorsIds);
  };

  const checkUpdatingSuccess = (
    changedPasswordSensors: SensorWithPasswordsPasswordModel[],
    devices: SensorWithPasswordsModel[]
  ): checkingSensorsResponse => {
    const selectedDevice = devices.filter((device) => device.sensorPasswords[0].deviceId === deviceId)[0];
    const currentErrorSensors = checkNotEqualSensors(selectedDevice, changedPasswordSensors);

    return {
      successUpdating: !currentErrorSensors.length,
      errorSensors: currentErrorSensors,
    };
  };

  const processUpdateResponse = async (changedPasswordSensors: SensorWithPasswordsPasswordModel[]): Promise<checkingSensorsResponse> => {
    const defaultErrorResponse: checkingSensorsResponse = { successUpdating: false, errorSensors: changedPasswordSensors };
    try {
      const devices = await getDevicesWithPasswordSensorsList();
      return !!devices ? checkUpdatingSuccess(changedPasswordSensors, devices) : defaultErrorResponse;
    } catch (error) {
      console.warn(error);
      return defaultErrorResponse;
    }
  };

  const onSave = async (oldSensors: SensorWithPasswordsPasswordModel[]) => {
    setIsLoading(true);
    setIsUpdateSuccess(null);
    setIsUpdateProcessFinished(null);
    await updateMultiplePasswordSensors(oldSensors);

    intervalRef.current = window.setInterval(() => {
      processUpdateResponse(oldSensors).then(({ successUpdating, errorSensors }) => {
        const maximumIntervalTimes = oldSensors.length;
        const currentCounterValue = counterRef.current;
        const newCounterValue = currentCounterValue + 1;
        const isLastAttempt = newCounterValue > maximumIntervalTimes;

        if (!successUpdating && !isLastAttempt) {
          setCounter(newCounterValue);
          return;
        }

        setIsLoading(false);
        setIsUpdateProcessFinished(true);
        setIsUpdateSuccess(successUpdating);
        setCounter(0);

        successUpdating && message.success(updateSuccessMessage, TIME_MESSAGE);
        !successUpdating && isLastAttempt && getErrorMessageWithSensors(errorSensors);
        intervalRef.current && clearInterval(intervalRef.current);
      });

      return () => intervalRef.current && clearInterval(intervalRef.current);
    }, TIME_DELAY_TO_UPDATE_ALL_PASSWORDS_SENSORS);
  };

  useEffect(() => {
    counterRef.current = counter;
  }, [counter]);

  useEffect(() => {
    isUpdateProcessFinished &&
      setTimeout(() => {
        setIsUpdateProcessFinished(false);
      }, 2000);
  }, [isUpdateProcessFinished]);

  return {
    errorSensorsIds,
    getDeviceId,
    onSave,
    resetUpdateStatusRequest,
    updateSuccess: isUpdateSuccess,
    updateProcessFinished: isUpdateProcessFinished,
    loadingUpdate: isLoading,
  };
};
