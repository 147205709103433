import { SensorWithPasswordsModel } from '../models/sensorEntityModels';

const payloadToSensorWithPasswordsItem = ({
  deviceName,
  deviceType,
  deviceParameters,
  sensorPasswords,
}: SensorWithPasswordsModel): SensorWithPasswordsModel => {
  return {
    gatewayId: sensorPasswords[0].gatewayId || '',
    deviceId: sensorPasswords[0].deviceId || '',
    deviceName,
    deviceType,
    deviceParameters,
    sensorPasswords,
  };
};

export const payloadToSensorsListWithPasswordsList = (
  sensorsList: SensorWithPasswordsModel[]
): { list: SensorWithPasswordsModel[] } => {
  return {
    list: sensorsList.map(payloadToSensorWithPasswordsItem),
  };
};
