import { payloadToGrantUserAccessToManyAssets } from '../mappers/payloadToGrantUserAccessToManyAssets';
import {
  AssetIdListModel,
  GrantUserAccessToManyAssetsResponseApiModel,
  GrantUserAccessToManyAssetsResponseModel,
} from '../models/grantUserAccessModel';
import axios, { AxiosRequestConfig } from 'axios';

const getUrl = (host: string, userId: string) => {
  return `${host}/users/${userId}/relationships/assets`;
};

interface GrantUserAccessToManyAssetsParams {
  host: string;
  token: string;
  assetIdList: AssetIdListModel[];
  userId: string;
}

export const GrantUserAccessToManyAssets = async ({
  host,
  token,
  assetIdList,
  userId,
}: GrantUserAccessToManyAssetsParams): Promise<GrantUserAccessToManyAssetsResponseModel> => {
  const url = getUrl(host, userId);
  
  try {
  const options: AxiosRequestConfig = {
    url,
    method: 'PATCH',
    headers: {
      Authorization: token,
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept-version': '1',
    },
    data: { data: assetIdList },
  };

    const { data } = await axios(options) as { data: GrantUserAccessToManyAssetsResponseApiModel };
    const response = payloadToGrantUserAccessToManyAssets(data);
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};
