import { Reducer, useReducer } from 'react';
import { Moment } from 'moment';
import {
  UpdateCheckInFormBoxProps,
  UpdateCheckInFormEmailInputValueProps,
  UpdateCheckInFormTextInputCardsProps,
  UpdateCheckInFormTextInputDateProps,
  UpdateCheckInFormTextInputTimeProps,
  UpdateCheckInFormTextInputValueProps,
  UpdateCheckInInitialStatesModel,
  UpdateCheckInInputFormKey,
  UpdateCheckInModalForceProps,
  UpdateCheckInQrModalProps
} from './models';
import {
  INITIAL_CARD1,
  INITIAL_CARD2,
  INITIAL_CARD3,
  INITIAL_CARD_LIST_BOX,
  INITIAL_DIGITAL_BOX,
  INITIAL_EMAILS,
  INITIAL_END_DATE,
  INITIAL_END_TIME,
  INITIAL_MODAL_FORCE,
  INITIAL_START_DATE,
  INITIAL_START_TIME,
  INITIAL_QR_MODAL,
} from './utils';
import { useTranslation } from 'react-i18next';

export const accessHotelUpdateCheckInitialState: UpdateCheckInInitialStatesModel = {
  [UpdateCheckInInputFormKey.QR_MODAL]: INITIAL_QR_MODAL,
  [UpdateCheckInInputFormKey.START_DATE]: INITIAL_START_DATE,
  [UpdateCheckInInputFormKey.END_DATE]: INITIAL_END_DATE,
  [UpdateCheckInInputFormKey.START_TIME]: INITIAL_START_TIME,
  [UpdateCheckInInputFormKey.END_TIME]: INITIAL_END_TIME,
  [UpdateCheckInInputFormKey.DIGITAL_BOX]: INITIAL_DIGITAL_BOX,
  [UpdateCheckInInputFormKey.EMAILS]: INITIAL_EMAILS,
  [UpdateCheckInInputFormKey.CARD_LIST_BOX]: INITIAL_CARD_LIST_BOX,
  [UpdateCheckInInputFormKey.MODAL_FORCE]: INITIAL_MODAL_FORCE,
  [UpdateCheckInInputFormKey.CARD_1]: INITIAL_CARD1,
  [UpdateCheckInInputFormKey.CARD_2]: INITIAL_CARD2,
  [UpdateCheckInInputFormKey.CARD_3]: INITIAL_CARD3,
};

export interface Action {
  key: string;
  payload:
  UpdateCheckInFormBoxProps |
  UpdateCheckInFormEmailInputValueProps |
  UpdateCheckInFormTextInputCardsProps |
  UpdateCheckInFormTextInputDateProps |
  UpdateCheckInFormTextInputTimeProps |
  UpdateCheckInFormTextInputValueProps |
  UpdateCheckInQrModalProps |
  UpdateCheckInModalForceProps |
  UpdateCheckInFormTextInputDateProps;
}

const reducer = (state: UpdateCheckInInitialStatesModel, action: Action) => ({ ...state, [action.key]: action.payload });

export const useReduceUpdateCheckInRoom = () => {
  const { t } = useTranslation();
  const [
    updateCheckInStates, dispatch
  ] = useReducer<Reducer<UpdateCheckInInitialStatesModel, Action>>(
    reducer, accessHotelUpdateCheckInitialState
  );
  const checkInRequiredFieldErrorText = t('_ACCESSES_HOTEL_UPDATE_CHECK_IN_REQUIRED_FIELD');
  
  const digitalBoxState = updateCheckInStates[UpdateCheckInInputFormKey.DIGITAL_BOX];
  const startDateState = updateCheckInStates[UpdateCheckInInputFormKey.START_DATE];
  const endDateState = updateCheckInStates[UpdateCheckInInputFormKey.END_DATE];
  const startTimeState = updateCheckInStates[UpdateCheckInInputFormKey.START_TIME];
  const endTimeState = updateCheckInStates[UpdateCheckInInputFormKey.END_TIME];
  const cardListBoxState = updateCheckInStates[UpdateCheckInInputFormKey.CARD_LIST_BOX];
  const emailsState = updateCheckInStates[UpdateCheckInInputFormKey.EMAILS];
  const card1State = updateCheckInStates[UpdateCheckInInputFormKey.CARD_1];
  const card2State = updateCheckInStates[UpdateCheckInInputFormKey.CARD_2];
  const card3State = updateCheckInStates[UpdateCheckInInputFormKey.CARD_3];
  const qrModalState = updateCheckInStates[UpdateCheckInInputFormKey.QR_MODAL];
  const modalForceState = updateCheckInStates[UpdateCheckInInputFormKey.MODAL_FORCE];

  const dispatchToDigitalBoxKey = (payload: Partial<UpdateCheckInFormBoxProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.DIGITAL_BOX,
      payload: {
        ...digitalBoxState,
        ...payload,
      },
    });
  };

  const dispatchToEmailKey = (payload: Partial<UpdateCheckInFormEmailInputValueProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.EMAILS,
      payload: {
        ...emailsState,
        ...payload,
      },
    });
  };

  const dispatchToCardListBoxKey = (payload: Partial<UpdateCheckInFormBoxProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.CARD_LIST_BOX,
      payload: {
        ...cardListBoxState,
        ...payload,
      },
    });
  };

  const dispatchToCard1Key = (payload: Partial<UpdateCheckInFormTextInputCardsProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.CARD_1,
      payload: {
        ...card1State,
        ...payload,
      },
    });
  };

  const dispatchToCard2Key = (payload: Partial<UpdateCheckInFormTextInputCardsProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.CARD_2,
      payload: {
        ...card2State,
        ...payload,
      },
    });
  };

  const dispatchToCard3Key = (payload: Partial<UpdateCheckInFormTextInputCardsProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.CARD_3,
      payload: {
        ...card3State,
        ...payload,
      },
    });
  };

  const dispatchToStartTimeKey = (payload: Partial<UpdateCheckInFormTextInputTimeProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.START_TIME,
      payload: {
        ...startTimeState,
        ...payload,
      },
    });
  };

  const dispatchToEndTimeKey = (payload: Partial<UpdateCheckInFormTextInputTimeProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.END_TIME,
      payload: {
        ...endTimeState,
        ...payload,
      },
    });
  };

  const dispatchToStartDateKey = (payload: Partial<UpdateCheckInFormTextInputDateProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.START_DATE,
      payload: {
        ...startDateState,
        ...payload,
      },
    });
  };

  const dispatchToEndDateKey = (payload: Partial<UpdateCheckInFormTextInputDateProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.END_DATE,
      payload: {
        ...endDateState,
        ...payload,
      },
    });
  };

  const dispatchToQrModalKey = (payload: Partial<UpdateCheckInQrModalProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.QR_MODAL,
      payload: {
        ...qrModalState,
        ...payload,
      },
    })
  };
  
  const dispatchToModalForceKey = (payload: Partial<UpdateCheckInModalForceProps>): void => {
    dispatch({
      key: UpdateCheckInInputFormKey.MODAL_FORCE,
      payload: {
        ...modalForceState,
        ...payload,
      },
    })
  };

  const setResetAllPayload = (key: UpdateCheckInInputFormKey) => {
    dispatch({
      key,
      payload: accessHotelUpdateCheckInitialState[key],
    });
  };

  const setResetError = (key: UpdateCheckInInputFormKey) => {
    dispatch({
      key,
      payload: {
        ...updateCheckInStates[key],
        isError: false,
        errorMessage: '',
      } as UpdateCheckInFormBoxProps |
        UpdateCheckInFormTextInputTimeProps |
        UpdateCheckInFormTextInputCardsProps |
        UpdateCheckInFormTextInputDateProps |
        UpdateCheckInFormTextInputValueProps
    });
  };

  const onChangeTime = (key: UpdateCheckInInputFormKey.START_TIME | UpdateCheckInInputFormKey.END_TIME, value: Moment | null) => {
    dispatch({
      key,
      payload: {
        ...updateCheckInStates[key],
        isError: !value,
        errorMessage: !value ? checkInRequiredFieldErrorText : '',
        value,
      } as UpdateCheckInFormTextInputTimeProps
    });
  };

  const setValueCard = (key: UpdateCheckInInputFormKey.CARD_1 | UpdateCheckInInputFormKey.CARD_2 | UpdateCheckInInputFormKey.CARD_3, value: string) => {
    dispatch({
      key: key,
      payload: {
        ...updateCheckInStates[key],
        value: value,
      } as UpdateCheckInFormTextInputCardsProps |
        UpdateCheckInFormTextInputValueProps
    });
  };

  const setRequiredError = (key: UpdateCheckInInputFormKey.START_DATE |
    UpdateCheckInInputFormKey.END_DATE |
    UpdateCheckInInputFormKey.START_TIME |
    UpdateCheckInInputFormKey.START_TIME |
    UpdateCheckInInputFormKey.END_TIME |
    UpdateCheckInInputFormKey.EMAILS |
    UpdateCheckInInputFormKey.CARD_1
  ) => {
    dispatch({
      key,
      payload: {
        ...updateCheckInStates[key],
        isError: true,
        errorMessage: checkInRequiredFieldErrorText,
      }
    });
  };

  return {
    state: {
      card1State,
      card2State,
      card3State,
      cardListBoxState,
      digitalBoxState,
      emailsState,
      endDateState,
      endTimeState,
      modalForceState,
      qrModalState,
      startDateState,
      startTimeState,
    },
    checkInRequiredFieldErrorText,
    dispatch: {
      dispatchToCard1Key,
      dispatchToCard2Key,
      dispatchToCard3Key,
      dispatchToCardListBoxKey,
      dispatchToDigitalBoxKey,
      dispatchToEmailKey,
      dispatchToEndDateKey,
      dispatchToEndTimeKey,
      dispatchToModalForceKey,
      dispatchToQrModalKey,
      dispatchToStartDateKey,
      dispatchToStartTimeKey,
    },
    onChangeTime,
    setRequiredError,
    setResetAllPayload,
    setResetError,
    setValueCard,
  }

}