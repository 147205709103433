import axios, { AxiosRequestConfig } from 'axios';
import { checkInRoomToPayload } from '../mappers';
import { CheckInRoomModel } from '../models';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string) => {
  return `${host}/hotels/assets/check-in`;
};

const USED_CODE = 226;

export const CheckInRoom = async ({
  data,
  token,
  host,
}: {
  data: CheckInRoomModel;
  token: string;
  host: string;
}): Promise<{ hasUsed: boolean }> => {
  try {
    const url = getUrl(host);
    const mappedData = checkInRoomToPayload(data);

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: mappedData },
    };

    const { status } = await axios(options);

    return status === USED_CODE ? { hasUsed: true } : { hasUsed: false };
  } catch (error) {
    throw getErrorFormat(error);
  }
};
