import { FC } from 'react';
import { useExternalInvitationsList } from './resources/useExternalInvitationsList';
import ExternalInvitationsListTemplate from 'components/template/ExternalInvitationsListTemplate';
import RevokeExternalInvitationModal from 'components/organisms/ExternalInvitationRevokeModal';
import ValidateExternalInvitationModal from 'components/organisms/ExternalInvitationValidateModal';

const ExternalInvitationsListTab: FC = () => {
  const {
    validateModalLiterals,
    revokeModalLiterals,
    externalInvitationsList,
    columnsExternalInvitationsList,
    totalPages,
    currentPage,
    pageSize,
    onChangePage,
    onAcceptRevokeModal,
    onCancelRevokeModal,
    onAcceptValidateModal,
    onCancelValidateModal,
    onFilter,
    isLoading,
    isLoadingRevoke,
    isVisibleRevokeModal,
    isLoadingValidate,
    isVisibleValidateModal,
  } = useExternalInvitationsList();
  return (
    <>
      <ExternalInvitationsListTemplate
        externalInvitationsList={externalInvitationsList}
        totalPages={totalPages}
        pageSize={pageSize}
        currentPage={currentPage}
        columns={columnsExternalInvitationsList}
        onChangePage={onChangePage}
        onFilter={onFilter}
        loading={isLoading}
      />
      <RevokeExternalInvitationModal
        {...revokeModalLiterals}
        onAccept={onAcceptRevokeModal}
        onCancel={onCancelRevokeModal}
        isLoading={isLoadingRevoke}
        isVisible={isVisibleRevokeModal}
      />
      <ValidateExternalInvitationModal
        {...validateModalLiterals}
        onAccept={onAcceptValidateModal}
        onCancel={onCancelValidateModal}
        isLoading={isLoadingValidate}
        isVisible={isVisibleValidateModal}
      />
    </>
  )
}

export default ExternalInvitationsListTab;