import { AutoComplete, Col, Input, Row } from 'antd';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export interface LinkSearchSelectV2Items {
  value: string;
  label: string;
}

interface Options {
  value: string;
  label: JSX.Element;
}

interface Props {
  dropdownMatchSelectWidth?: number;
  enterButton?: ReactNode;
  placeholder: string;
  defaultValue?: LinkSearchSelectV2Items;
  onSearch: (query: string) => Promise<LinkSearchSelectV2Items[]>;
  onSelect: (item?: LinkSearchSelectV2Items) => void;
  isSearchInputOpen: boolean;
}

const LinkSearchSelectV2OptionsToOptions = (itemList: LinkSearchSelectV2Items[]): Options[] => {
  return itemList.map(({ value, label }) => {
    return {
      value,
      label: (
        <Row justify={'space-between'}>
          <Col>{label}</Col>
        </Row>
      ),
    };
  })
};

const LinkSearchSelectV2: FC<Props> = ({
  dropdownMatchSelectWidth = 400,
  placeholder,
  enterButton,
  defaultValue,
  onSearch,
  onSelect,
  isSearchInputOpen,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(defaultValue?.label || '');
  const [optionsList, setOptionsList] = useState<Options[]>([]);
  const [itemList, setItemList] = useState<LinkSearchSelectV2Items[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    defaultValue && setValue(defaultValue?.label || '');
  }, [defaultValue])

  const handleOnSearch = async (query: string): Promise<void> => {
    setIsLoading(true);
    try {
      const optionsList = await onSearch(query);
      setItemList(optionsList || []);
      setOptionsList(LinkSearchSelectV2OptionsToOptions(optionsList || []));
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChange = (value: string) => {
    if (!value) {
      setValue(value);
      setItemList([]);
      setOptionsList(LinkSearchSelectV2OptionsToOptions([]));
    }
    setValue(value);
  };

  const handleOnSelect = (userId: string) => {
    onSelect(itemList.find(option => option.value === userId));
  };

  return (
    <AutoComplete
      open={isSearchInputOpen}
      notFoundContent={optionsList ? t('no_results') : undefined}
      className='AddUserIntoAsset__input'
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      options={optionsList}
      onSelect={handleOnSelect}
      onSearch={handleOnSearch}
      value={value}
      onClear={() => {
        onSelect();
        setValue('');
      }}
    >
      <Input.Search
        loading={isLoading}
        placeholder={placeholder}
        enterButton={enterButton}
        onChange={(event) => {
          handleOnChange(event.target.value)
        }}
        value={value}
        allowClear
      />
    </AutoComplete>
  );
};

export default LinkSearchSelectV2;
