import api from 'services/api';
import { User, UserResponse, UsersResponse, UserInvitation, UserRole, RegisterUser, UserProfile } from 'models/users.model';
import { RegisterUserFormValuesType } from 'components/organisms/RegisterUserForm';

export const getUser = async (userId: string): Promise<UserResponse> => await api.get(`/users/${userId}`);

interface GetUsersOptions {
  current?: number;
  size?: number;
  search?: string;
}

export const getUsers = async (options?: GetUsersOptions): Promise<UsersResponse> => {
  const params = new URLSearchParams();
  if (options?.current) params.append('page[number]', `${options.current}`);
  if (options?.size) params.append('page[size]', `${options.size}`);
  if (options?.search) params.append('search', `${options.search}`);
  return await api.get('/users', { params });
};

export const getProfileUser = async (): Promise<UserProfile> => {
  const { data } = await api.get('/users/current');
  return data;
};

// TODO: refactorizar este método cuando se acepte roles y no types
export const getExpertUsers = async (): Promise<UsersResponse> => {
  return await api.get(`/users?filter='type=contractor'`);
};

export const getUserSearch = async (query: string): Promise<User[]> => {
  const params = new URLSearchParams();
  params.append('search', `${query}`);
  const response = await api.get('/users', {
    params,
  });
  return response.data;
};

export const getRoleUser = async (): Promise<UserRole[]> => {
  const { data } = await api.get('/users/roles');
  return data;
};

export const addUserAccessToAsset = async (userId: string, assetId: string): Promise<void> => {
  return await api.patch(`/users/${userId}/asset/${assetId}/relate`);
};

export const revokeUserAccessToAsset = async (userId: string, assetId: string): Promise<void> =>
  await api.request({
    method: 'delete',
    url: `/users/${userId}/relationships/assets`,
    data: {
      data: [{ id: assetId }],
    },
});

export const deleteUser = async (userId: string): Promise<{ detail: string }> => await api.delete(`/users/${userId}`);

export const invitationUser = async (data: UserInvitation): Promise<void> => {
  await api.post('/users/register/invitation', {
    data,
  });
};

export const registerUser = async (values: RegisterUserFormValuesType): Promise<RegisterUser> => {
  const currentUrl: string = window.location.href;
  const url = new URL(currentUrl);
  const token = url.searchParams.get('token') || '';
  const data: RegisterUser = {
    name: values.name,
    email: values.email,
    password: values.password,
    cellphone: values.cellphone,
    terms_of_service: values.terms_of_service,
    token,
  };

  const response: RegisterUser = await api.post('/users/register/by_token', { data });
  return response;
};

export const assignExpertUserToClient = (clientId: string, userExpertId: string): Promise<void> => {
  const data: string[] = [clientId];
  return api.patch(`/users/${userExpertId}/relationships/clients`, { data });
};
