import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputRef } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getAsset } from 'services/api/assets';
import { GetDevicesLists } from 'core/domain/devices/repositories/getDevicesList';
import { DeviceModel, DeviceSensorModel } from 'core/domain/devices/models';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import { ExpandedPeripheralDevicesTranslations } from 'components/organisms/PeripheralDevices/ExpandedColumnsPeripheralsDevices';
import { ColumnSearchTranslationsType, DeviceDataIndex } from 'components/organisms/PeripheralDevices/SearchPeripheralColumns';
import {
  ColumnsPeripheralDevices,
  ColumnsPeripheralDevicesOptionsModel,
} from 'components/organisms/PeripheralDevices/ColumnsPeripheralDevices';
import { Device, DeviceSensor } from 'models/devices.model';
import { useCreateDeviceWithoutGateway } from './useCreateDeviceWithoutGateway';
import { useUpdateDeviceWithoutGateway } from './useUpdateDeviceWithoutGateway';
import { useDeleteDeviceWithoutGateway } from './useDeleteDeviceWithoutGateway';

export const useTabDevices = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const { assetId } = useParams<{ assetId: string }>();
  const [loading, setLoading] = useState(true);
  const [gatewayId, setGatewayId] = useState<string>('');
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const [maintenanceDevice, setMaintenanceDevice] = useState<Device>();
  const [isMaintenanceActionsDrawerVisible, setIsMaintenanceActionsDrawerVisible] = useState<boolean>(false);
  const { createDeviceOptions, successCreatingDevice } = useCreateDeviceWithoutGateway();
  const { updateDeviceOptions, successUpdatingDevice } = useUpdateDeviceWithoutGateway();
  const { deleteDeviceOptions, successDeletingDevice } = useDeleteDeviceWithoutGateway();

  const searchInput = useRef<InputRef>(null);

  const peripheralsTitle: string = t('peripheral');

  const peripheralExpandedColumnsTranslations: ExpandedPeripheralDevicesTranslations = {
    nameText: t('name'),
    roomText: t('room'),
    typeText: t('type'),
    usageText: t('usage'),
    notReportedText: t('not_reported'),
  };

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
    notReportedText: t('not_reported'),
  };

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await getAsset(assetId);
      !!data.gateways_qrs.length && setGatewayId(data.gateways_qrs[0].gateway_id);
      const devicesData = await GetDevicesLists({ host, token, assetId });
      setDevices(devicesData);
    } catch (error) {
      setMessageError({ description: t('_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  const transformSensorToMaintenanceSensor = ({ sensorId, sensorType, name, room, usage }: DeviceSensorModel): DeviceSensor => {
    return {
      item_name: '',
      item_type: '',
      sensor_id: sensorId,
      sensor_type: sensorType,
      name,
      room,
      usage: usage ?? undefined,
    };
  };

  const transformDeviceToMaintenanceDevice = ({
    availableActions,
    batteryLevel,
    deviceId,
    deviceName,
    deviceType,
    enabled,
    parameters,
    sensors,
  }: DeviceModel): Device => {
    const transformedSensors = sensors.map((sensor) => transformSensorToMaintenanceSensor(sensor)) ?? [];
    return {
      available_actions: availableActions,
      battery_level: batteryLevel,
      device_id: deviceId,
      device_name: deviceName,
      device_type: deviceType,
      enabled,
      parameters,
      password: '',
      thing_id: '',
      sensors: transformedSensors,
    };
  };

  const onOpenMaintenanceActionsDrawer = (selectedDevice: DeviceModel) => {
    setIsMaintenanceActionsDrawerVisible(true);
    const newMaintenanceDevice = transformDeviceToMaintenanceDevice(selectedDevice);
    setMaintenanceDevice(newMaintenanceDevice);
  };

  const onCloseMaintenanceActionsDrawer = () => {
    setIsMaintenanceActionsDrawerVisible(false);
    setMaintenanceDevice(undefined);
  };

  const columnsOptions: ColumnsPeripheralDevicesOptionsModel = {
    columnSearchTranslations,
    searchInput,
    onSearch,
    onReset,
    onOpenMaintenanceActionsDrawer,
    onOpenUpdateDeviceDrawer: (device: DeviceModel) => updateDeviceOptions.onOpenUpdateDeviceDrawer(device),
    onOpenDeleteDeviceModal: (device: DeviceModel) => deleteDeviceOptions.onOpenDeleteDeviceModal(device),
  };

  const columns = ColumnsPeripheralDevices({ options: columnsOptions });

  const maintenanceActionsDrawerOptions = {
    gatewayId,
    maintenanceDevice,
    onCloseMaintenanceActionsDrawer,
    isMaintenanceActionsDrawerVisible,
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    (!!successCreatingDevice || !!successUpdatingDevice || !!successDeletingDevice) && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCreatingDevice, successUpdatingDevice, successDeletingDevice]);

  return {
    devices,
    peripheralExpandedColumnsTranslations,
    searchInput,
    columnSearchTranslations,
    createDeviceOptions,
    updateDeviceOptions,
    deleteDeviceOptions,
    gatewayId,
    peripheralsTitle,
    searchedColumn,
    columns,
    maintenanceActionsDrawerOptions,
    onSearch,
    onReset,
    loading,
  };
};
