export enum HotelAccessesTabType {
  ROOM_LIST = 'access-list',
  CHECK_IN_LIST = 'check-in-list',
  ACCESS_DEVICES_LIST = 'office-access-devices-list',
}

export enum OfficeAccessesTabType {
  LOG_LIST = 'log-list',
  ACCESS_DEVICES_LIST = 'office-access-devices-list',
  ACCESS_EXTERNAL_INVITATIONS = 'external-invitations-list',
}

export enum RouteSegmentType {
  DEFAULT_SEGMENT = 3,
  LAST_CLIENT_AND_PROJECT = 7,
  CLIENT_AND_PROJECT_SEGMENT = 8,
  ACCESS_LIST_SEGMENT = 9,
  ACCESS_DETAIL_SEGMENT = 10,
}

export interface TabTranslations {
  roomsListText: string;
  checkInListText: string;
  logListText: string;
  accessDevicesListText: string;
  accessExternalInvitationsListText: string;
}
