import { FC } from 'react';
import { Form, FormProps, Input } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './styles.scss';

export enum WaterMeterWm1000nbFormValuesNames {
  COMMUNICATION_PLATFORM_IP = 'communication_platform_ip',
  COMMUNICATION_PLATFORM_PORT = 'communication_platform_port',
  REPORTING_INTERVAL_TIME = 'reporting_interval_time',
  REPORTING_INTERVAL_NUMBER = 'reporting_interval_number',
  SECRET_KEY = 'secret_key',
  SERIAL_NUMBER = 'serial_number',
  NAME = 'name',
  ROOM = 'room',
  SENSOR_COUNT_UP_NAME = 'count_up_name',
  SENSOR_COUNT_UP_ROOM = 'count_up_room',
  SENSOR_COUNT_DOWN_NAME = 'count_down_name',
  SENSOR_COUNT_DOWN_ROOM = 'count_down_room',
  SENSOR_BATTERY_NAME = 'battery_name',
  SENSOR_BATTERY_ROOM = 'battery_room',
}

export interface WaterMeterWm1000nbFormValues {
  [WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_IP]: string | null;
  [WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_PORT]: string | null;
  [WaterMeterWm1000nbFormValuesNames.REPORTING_INTERVAL_TIME]: string;
  [WaterMeterWm1000nbFormValuesNames.REPORTING_INTERVAL_NUMBER]: string;
  [WaterMeterWm1000nbFormValuesNames.SECRET_KEY]: string | null;
  [WaterMeterWm1000nbFormValuesNames.SERIAL_NUMBER]: string;
  [WaterMeterWm1000nbFormValuesNames.NAME]: string | null;
  [WaterMeterWm1000nbFormValuesNames.ROOM]: string;
  [WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_UP_NAME]: string | null;
  [WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_UP_ROOM]: string | null;
  [WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_DOWN_NAME]: string | null;
  [WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_DOWN_ROOM]: string | null;
  [WaterMeterWm1000nbFormValuesNames.SENSOR_BATTERY_NAME]: string | null;
  [WaterMeterWm1000nbFormValuesNames.SENSOR_BATTERY_ROOM]: string | null;
}

export const CREATE_FORM_INITIAL_VALUES: WaterMeterWm1000nbFormValues = {
  [WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_IP]: null,
  [WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_PORT]: null,
  [WaterMeterWm1000nbFormValuesNames.REPORTING_INTERVAL_TIME]: '',
  [WaterMeterWm1000nbFormValuesNames.REPORTING_INTERVAL_NUMBER]: '',
  [WaterMeterWm1000nbFormValuesNames.SECRET_KEY]: null,
  [WaterMeterWm1000nbFormValuesNames.SERIAL_NUMBER]: '',
  [WaterMeterWm1000nbFormValuesNames.NAME]: null,
  [WaterMeterWm1000nbFormValuesNames.ROOM]: '',
  [WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_UP_NAME]: null,
  [WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_UP_ROOM]: null,
  [WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_DOWN_NAME]: null,
  [WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_DOWN_ROOM]: null,
  [WaterMeterWm1000nbFormValuesNames.SENSOR_BATTERY_NAME]: null,
  [WaterMeterWm1000nbFormValuesNames.SENSOR_BATTERY_ROOM]: null,
};

export interface WaterMeterWm1000nbFormLiterals {
  deviceSectionTitle: string;
  ipLabel: string;
  ipPlaceholder: string;
  portLabel: string;
  portPlaceholder: string;
  intervalTimeLabel: string;
  intervalTimePlaceholder: string;
  intervalNumberLabel: string;
  intervalNumberPlaceholder: string;
  secretKeyLabel: string;
  secretKeyPlaceholder: string;
  serialNumberLabel: string;
  serialNumberPlaceholder: string;
  nameLabel: string;
  namePlaceholder: string;
  roomLabel: string;
  roomPlaceholder: string;
  sensorSectionTitle: string;
  sensorSectionDescription: string;
  countUpSensorText: string;
  countUpSensorNameLabel: string;
  countUpSensorNamePlaceholder: string;
  countUpSensorRoomLabel: string;
  countUpSensorRoomPlaceholder: string;
  countDownSensorText: string;
  countDownSensorNameLabel: string;
  countDownSensorNamePlaceholder: string;
  countDownSensorRoomLabel: string;
  countDownSensorRoomPlaceholder: string;
  batterySensorText: string;
  batterySensorNameLabel: string;
  batterySensorNamePlaceholder: string;
  batterySensorRoomLabel: string;
  batterySensorRoomPlaceholder: string;
  requiredFieldTextLegend: string;
  requiredFieldText: string;
}

export interface WaterMeterWm1000nbOptionsModel {
  form: FormInstance<WaterMeterWm1000nbFormValues>;
  literals: WaterMeterWm1000nbFormLiterals;
  onFinish: (values: WaterMeterWm1000nbFormValues) => void;
  onFinishFailed: () => void;
}

interface Props extends FormProps {
  waterMeterWm1000nbOptions: WaterMeterWm1000nbOptionsModel;
  deviceSectionNumber?: number;
  sensorsSectionNumber?: number;
  loading: boolean;
}

const WaterMeterWm1000nbForm: FC<Props> = ({ waterMeterWm1000nbOptions, deviceSectionNumber, sensorsSectionNumber, loading }) => {
  const { form, literals, onFinish, onFinishFailed } = waterMeterWm1000nbOptions;
  const {
    deviceSectionTitle,
    ipLabel,
    ipPlaceholder,
    portLabel,
    portPlaceholder,
    secretKeyLabel,
    secretKeyPlaceholder,
    serialNumberLabel,
    serialNumberPlaceholder,
    intervalNumberLabel,
    intervalNumberPlaceholder,
    intervalTimeLabel,
    intervalTimePlaceholder,
    nameLabel,
    namePlaceholder,
    roomLabel,
    roomPlaceholder,
    sensorSectionTitle,
    sensorSectionDescription,
    countUpSensorText,
    countUpSensorNameLabel,
    countUpSensorNamePlaceholder,
    countUpSensorRoomLabel,
    countUpSensorRoomPlaceholder,
    countDownSensorText,
    countDownSensorNameLabel,
    countDownSensorNamePlaceholder,
    countDownSensorRoomLabel,
    countDownSensorRoomPlaceholder,
    batterySensorText,
    batterySensorNameLabel,
    batterySensorNamePlaceholder,
    batterySensorRoomLabel,
    batterySensorRoomPlaceholder,
    requiredFieldText,
    requiredFieldTextLegend,
  } = literals;

  const deviceSectionOrder: string = !!deviceSectionNumber ? String(deviceSectionNumber) : '';
  const sensorsSectionOrder: string = !!sensorsSectionNumber ? String(sensorsSectionNumber) : '';

  return (
    <Form
      form={form}
      layout={'vertical'}
      requiredMark={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      disabled={loading}
    >
      <div className={'WaterMeterWm1000nbForm__text-wrapper'}>
        <p className={'WaterMeterWm1000nbForm__title'}>{`${deviceSectionOrder}. ${deviceSectionTitle}`}</p>
      </div>
      <div className={'WaterMeterWm1000nbForm__wrapper'}>
        <div className={'WaterMeterWm1000nbForm__wrapper-row'}>
          <Form.Item
            label={`${nameLabel}*`}
            name={WaterMeterWm1000nbFormValuesNames.NAME}
            rules={[{ required: true, message: requiredFieldText, type: 'string' }]}
          >
            <Input placeholder={namePlaceholder} />
          </Form.Item>
          <Form.Item
            label={`${roomLabel}*`}
            name={WaterMeterWm1000nbFormValuesNames.ROOM}
            rules={[{ required: true, message: requiredFieldText, type: 'string' }]}
          >
            <Input placeholder={roomPlaceholder} />
          </Form.Item>
        </div>
        <div className={'WaterMeterWm1000nbForm__wrapper-row'}>
          <Form.Item
            label={`${intervalTimeLabel}*`}
            name={WaterMeterWm1000nbFormValuesNames.REPORTING_INTERVAL_TIME}
            rules={[{ required: true, message: requiredFieldText, type: 'string' }]}
          >
            <Input placeholder={intervalTimePlaceholder} />
          </Form.Item>
          <Form.Item
            label={`${intervalNumberLabel}*`}
            name={WaterMeterWm1000nbFormValuesNames.REPORTING_INTERVAL_NUMBER}
            rules={[{ required: true, message: requiredFieldText, type: 'string' }]}
          >
            <Input placeholder={intervalNumberPlaceholder} />
          </Form.Item>
        </div>
        <div className={'WaterMeterWm1000nbForm__wrapper-row'}>
          <Form.Item
            label={ipLabel}
            name={WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_IP}
          >
            <Input placeholder={ipPlaceholder} />
          </Form.Item>
          <Form.Item
            label={portLabel}
            name={WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_PORT}
          >
            <Input placeholder={portPlaceholder} />
          </Form.Item>
        </div>
        <div className={'WaterMeterWm1000nbForm__wrapper-row'}>
          <Form.Item
            label={`${secretKeyLabel}*`}
            name={WaterMeterWm1000nbFormValuesNames.SECRET_KEY}
            rules={[{ required: true, message: requiredFieldText, type: 'string' }]}
          >
            <Input placeholder={secretKeyPlaceholder} />
          </Form.Item>
          <Form.Item
            label={`${serialNumberLabel}*`}
            name={WaterMeterWm1000nbFormValuesNames.SERIAL_NUMBER}
            rules={[{ required: true, message: requiredFieldText, type: 'string' }]}
          >
            <Input placeholder={serialNumberPlaceholder} />
          </Form.Item>
        </div>
      </div>
      <div className={'WaterMeterWm1000nbForm__text-wrapper'}>
        <p className={'WaterMeterWm1000nbForm__title'}>{`${sensorsSectionOrder}. ${sensorSectionTitle}`}</p>
        <p>{sensorSectionDescription}</p>
      </div>
      <div className={'WaterMeterWm1000nbForm__wrapper'}>
        <div>
          <p>{countUpSensorText}</p>
          <div className={'WaterMeterWm1000nbForm__wrapper-row'}>
            <Form.Item
              label={countUpSensorNameLabel}
              name={WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_UP_NAME}
            >
              <Input placeholder={countUpSensorNamePlaceholder} />
            </Form.Item>
            <Form.Item
              label={countUpSensorRoomLabel}
              name={WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_UP_ROOM}
            >
              <Input placeholder={countUpSensorRoomPlaceholder} />
            </Form.Item>
          </div>
        </div>
        <div>
          <p>{countDownSensorText}</p>
          <div className={'WaterMeterWm1000nbForm__wrapper-row'}>
            <Form.Item
              label={countDownSensorNameLabel}
              name={WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_DOWN_NAME}
            >
              <Input placeholder={countDownSensorNamePlaceholder} />
            </Form.Item>
            <Form.Item
              label={countDownSensorRoomLabel}
              name={WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_DOWN_ROOM}
            >
              <Input placeholder={countDownSensorRoomPlaceholder} />
            </Form.Item>
          </div>
        </div>
        <div>
          <p>{batterySensorText}</p>
          <div className={'WaterMeterWm1000nbForm__wrapper-row'}>
            <Form.Item
              label={batterySensorNameLabel}
              name={WaterMeterWm1000nbFormValuesNames.SENSOR_BATTERY_NAME}
            >
              <Input placeholder={batterySensorNamePlaceholder} />
            </Form.Item>
            <Form.Item
              label={batterySensorRoomLabel}
              name={WaterMeterWm1000nbFormValuesNames.SENSOR_BATTERY_ROOM}
            >
              <Input placeholder={batterySensorRoomPlaceholder} />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className={'WaterMeterWm1000nbForm__text-wrapper'}>
        <p className={'WaterMeterWm1000nbForm__text-wrapper-legend'}>{`(*) ${requiredFieldTextLegend}`}</p>
      </div>
    </Form>
  );
}

export default WaterMeterWm1000nbForm;